import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { getInstagramPhotos } from 'services/api';
import PreloaderService, { preloadTypes } from 'services/preloader';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { SPA_ROUTES } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';

import Processing from './p7-Processing';

export interface LoaderProps {
  userCode: string;
  copyModals: GlobalCopy['modals'];
}

const defaultProps: Partial<LoaderProps> = {};

const Loader: React.FC<LoaderProps> = ({ userCode, copyModals }) => {
  const mountedRef = useRef(true);
  const {
    setCurrentPage,
    setImageSource,
    setIsUserSelected,
    setImageUrl,
    setLastSelectedUrls,
    setShowAtLeast3PostsError,
    setAllUrls,
    playProcessingExitAnim,
    setAiError,
  } = useGlobalStore();
  const { theme } = useThemeStore();
  const [loadProgress, setLoadProgress] = useState<number>(0);

  PreloaderService(
    preloadTypes.EXPERIENCE,
    theme,
    (value: number) => {
      if (mountedRef.current) setLoadProgress(value);
    },
    () => {
      if (!mountedRef.current) return null;
      WebGLContainer.eventManager?.trigger('build_can_scene');

      if (userCode) {
        const manualSelectionIG = JSON.parse(
          window.localStorage.getItem('manualSelectionIG')
        );
        setImageSource('IG');
        setIsUserSelected(manualSelectionIG);

        setTimeout(() => {
          getInstagramPhotos(
            userCode,
            setAiError,
            setCurrentPage,
            setImageUrl,
            setLastSelectedUrls,
            setShowAtLeast3PostsError,
            setAllUrls,
            playProcessingExitAnim,
            manualSelectionIG
          );
        }, 1000);
        // bad way of doing this but :/

        manualSelectionIG
          ? setCurrentPage(SPA_ROUTES.FAKEGALLERY)
          : setCurrentPage(SPA_ROUTES.PROCESSING);
      } else {
        setCurrentPage(SPA_ROUTES.LANDING);
      }
    }
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <motion.div className="loader" {...pageMotionProps}>
      <Processing percentLoad={loadProgress} copyModals={copyModals} />
    </motion.div>
  );
};

Loader.defaultProps = defaultProps;

export default Loader;
