import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  ${({ theme }) => `color: ${theme.global.colors.font};`}
  ${({ theme: { locale } }) => setTypography('body1', locale)}


  > article {
    > section {
      height: 80vh;
      overflow-y: scroll;
      margin-top: 30rem;
      padding-bottom: 350rem;
      padding-right: 30rem;

      > .content {
        text-align: left;
        margin-bottom: 50rem;
        ${({ theme: { locale } }) => setTypography('body1', locale)}
        font-size: 44rem;
      }
    }
    a {
      ${({ theme: { locale } }) => setTypography('body1', locale)}
      ${({ theme }) => `color: ${theme.global.colors.font};`}
      text-decoration: underline;
      font-size: 44rem;
    }
    > h3 {
      text-align: center;
      margin-bottom: 15rem;
      ${({ theme: { locale } }) => setTypography('body1', locale)}
    }

    > p.MsoNormal,
    > li.MsoNormal,
    > div.MsoNormal {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0in;
      line-height: 107%;
      font-size: 11pt;
    }
    > a:link,
    > span.MsoHyperlink {
      font-family: 'Roboto Bold';
      ${({ theme }) => `color: ${theme.global.colors.font};`}
      text-decoration: underline;
      text-underline-offset: 3px;
      font-size: 44rem;
    }
    > p.MsoListParagraph,
    > li.MsoListParagraph,
    > div.MsoListParagraph {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: 'Calibri', sans-serif;
    }
    > p.MsoListParagraphCxSpFirst,
    > li.MsoListParagraphCxSpFirst,
    > div.MsoListParagraphCxSpFirst {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: 'Calibri', sans-serif;
    }
    > p.MsoListParagraphCxSpMiddle,
    > li.MsoListParagraphCxSpMiddle,
    > div.MsoListParagraphCxSpMiddle {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 0in;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: 'Calibri', sans-serif;
    }
    > p.MsoListParagraphCxSpLast,
    > li.MsoListParagraphCxSpLast,
    > div.MsoListParagraphCxSpLast {
      margin-top: 0in;
      margin-right: 0in;
      margin-bottom: 8pt;
      margin-left: 0.5in;
      line-height: 107%;
      font-size: 11pt;
      font-family: 'Calibri', sans-serif;
    }
    span.xcontentpasted1 {
      mso-style-name: x_contentpasted1;
    }
    .MsoChpDefault {
      font-family: 'Calibri', sans-serif;
    }
    .MsoPapDefault {
      margin-bottom: 8pt;
      line-height: 107%;
    }

    > ol {
      margin-bottom: 0in;
    }
    > ul {
      margin-bottom: 0in;
    }
  }
`;
