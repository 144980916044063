import styled, { css } from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const WrapperButton = styled.button<{
  isDisabled?: boolean;
}>`
  > svg {
    height: 75rem;
    ${({ theme }) => `color: ${theme.global.colors.font};`}
  }
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
    `}
`;
export const WrapperArtwork = styled(Wrapper)`
  display: block;
  height: ${setVh(100)};
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  > .gifWait section p {
    margin: 0;
  }

  > .heading {
    position: absolute;
    height: 70rem;
    width: auto;
    top: ${({ theme: { locale } }) =>
      locale === 'en' ? '180rem' : locale === 'pk' ? '140rem' : '120rem'};
    transform: translateX(-50%);
    ${({ theme: { locale } }) => setTypography('heading3', locale)}
    font-size: 80rem;
    left: ${({ theme: { locale } }) => (locale === 'vi' ? '46%' : '50%')};
  }

  > .can {
    display: block;
    height: auto;
    width: 90%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
  }

  .bottomWrapper {
    width: 107%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 5%;

    > button {
      height: 180rem;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        height: 55rem;
      }
      > svg {
        transition: opacity 0.4s;
      }

      &:disabled {
        > div,
        > svg {
          opacity: 0.3;
        }
      }

      > div {
        ${({ theme: { locale } }) => setTypography('heading3', locale)}
        ${({ theme }) => `color: ${theme.global.colors.font};`}
        height: 70rem;
      }
    }

    > button:first-of-type {
      margin-right: 30rem;
      width: 180rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.white};

      > svg {
        height: 75rem;
        ${({ theme }) => `color: ${theme.global.colors.font};`}
      }
    }

    > button:last-of-type {
      width: 61%;
      margin: 0;

      > img {
        height: ${({ theme: { locale } }) => locale === 'es' && '65rem'};
      }
    }
  }
`;
