import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import logo from 'assets/img/mirindaLogo.png';
import Button from 'components/Button/Button';
import Heading from 'components/Heading/Heading';
import TermsModal from 'components/TermsModal/TermsModal';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import GTM from 'u9/utils/gtm';
import { SPA_ROUTES } from 'utils/routes';
import { loginMotionProps, pageMotionProps } from 'utils/styles/animations';
import { colors } from 'utils/styles/theme';

import * as Styled from './styles/p2-Landing.styles';

export interface LandingProps {
  copy: GlobalCopy['landing'];
}

const defaultProps: Partial<LandingProps> = {};

const Landing: React.FC<LandingProps> = ({ copy }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showTC, setShowTC] = useState(true);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [cookieCopy, setCookieCopy] = useState<string>('');
  const teconsentRef = useRef<HTMLSpanElement>(null);
  const teconsentCheckerRef = useRef<ReturnType<typeof setInterval>>(null);
  const { theme } = useThemeStore();
  const { setCurrentPage, setIsTermsAndConditions } = useGlobalStore();

  useEffect(() => {
    GTM.trackPage('/', 'Landing Page');
  }, []);

  useEffect(() => {
    clearInterval(teconsentCheckerRef.current);
    teconsentCheckerRef.current = setInterval(() => {
      if (teconsentRef.current) {
        const teconsentImg = teconsentRef.current.querySelector('img');
        if (teconsentImg) {
          clearInterval(teconsentCheckerRef.current);
          setCookieCopy(teconsentImg.alt);
        }
      }
    }, 25);
  }, []);

  return (
    <motion.div {...pageMotionProps}>
      <Styled.WrapperLanding>
        <img src={logo} alt="" />
        <Heading title={copy.title} imgUrl={theme.headings?.landing} />
        <Button
          label={copy.cta}
          imgUrl={theme.ctas?.landing}
          onClick={() => {
            GTM.trackEvent('Landing Page', 'Click', 'Start Experience');
            setCurrentPage(SPA_ROUTES.INTRO);
          }}
          whiteBg
        />
        <AnimatePresence>
          {showTC && (
            <motion.section
              key="terms"
              className="terms"
              // onClick={() => setShowTC(false)}
              {...loginMotionProps}
            >
              <p>
                <span dangerouslySetInnerHTML={{ __html: copy.terms }} />
                <small
                  dangerouslySetInnerHTML={{ __html: copy.termsCta }}
                  onClick={() => {
                    setShowTermsModal(true);
                    setIsTermsAndConditions(true);
                  }}
                />
                <span>.</span>
              </p>
              <div
                className="cookie-preferences"
                style={{
                  borderTop: `solid ${cookieCopy.length > 0 ? 1 : 0}px ${
                    colors.concrete
                  }`,
                  marginTop: `${cookieCopy.length > 0 ? 39 : 0}rem`,
                }}
              >
                {cookieCopy}
              </div>
              <span
                ref={teconsentRef}
                id="teconsent"
                className="cookie-preferences"
              ></span>
            </motion.section>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showTermsModal && (
            <TermsModal
              onClose={() => {
                setShowTermsModal(false);
                setIsTermsAndConditions(false);
              }}
            />
          )}
        </AnimatePresence>
      </Styled.WrapperLanding>
    </motion.div>
  );
};

Landing.defaultProps = defaultProps;

export default Landing;
