import styled, { css } from 'styled-components';

interface WrapperProps {
  $isSelected: boolean;
  $scaleReversed: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  height: 33.3vw;
  width: 33.3vw;

  > span {
    height: 100%;
    width: 100%;

    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      padding: 3rem;
      filter: grayscale(0%);
      transition: all 0.3s ease-in-out !important;
    }

    ${({ $scaleReversed }) =>
      $scaleReversed &&
      css`
        > img {
          transform: scale(-1);
        }
      `}
  }

  > svg {
    position: absolute;
    top: 15%;
    right: 10%;
    color: orange;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      > svg {
        opacity: 1;
      }
      > span > img {
        filter: grayscale(80%);
      }
    `};
`;
