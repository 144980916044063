import styled from 'styled-components';

import { setTypography, setVh } from 'utils/styles/mixins';

export const Section = styled.section<{ $isLandscape: boolean }>`
  min-height: ${setVh(100)};
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ${({ $isLandscape }) =>
    $isLandscape ? 'opacity 0.3s 0s' : 'opacity 0.3s 0.6s'};
  opacity: ${({ $isLandscape }) => ($isLandscape ? 0 : 1)};

  .loader {
    img {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  max-height: ${setVh(100)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    ${({ theme: { locale } }) => setTypography('heading1', locale)}
    margin: 50rem;
    color: white;
    text-shadow: -4px 6px 5px rgba(0, 0, 0, 0.25);
  }

  > img {
    width: 80%;
  }
`;
