import styled from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setTypography, setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const WrapperLanding = styled(Wrapper)`
  justify-content: space-around;
  height: ${setVh(100)};

  > img:first-of-type {
    margin-top: 100rem;
    margin-bottom: -60rem;
  }

  .heading {
    ${({ theme: { locale } }) => setTypography('heading3', locale)}
    font-size: 140rem;
  }

  > button {
    margin-bottom: ${setVh(15)};
  }

  .terms {
    background-color: ${colors.white};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 40rem;
    text-align: center;
    width: 90%;
    ${({ theme: { locale } }) => setTypography('terms', locale)}
    position: fixed;
    bottom: 0;

    a,
    small {
      font-family: 'Roboto Bold';
      ${({ theme }) => `color: ${theme.global.colors.font};`}
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .cookie-preferences {
    padding-top: 26rem;
    text-align: center;
    width: 100%;
    font-family: 'Roboto Bold';
    ${({ theme }) => `color: ${theme.global.colors.font};`};

    > a {
      left: 50%;
      position: fixed;
      transform: translate(-50%, -50%);
      opacity: 0 !important;
    }
  }

  > .infoModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: ${layout.zIndex.modals};

    > .dimBg {
      position: absolute;
      background-color: ${colors.black};
      opacity: 0.5;
      width: 100%;
      height: 100%;
      top: 0;
    }

    > .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: auto;
      max-height: 90%;
      background-color: ${colors.white};
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      border-radius: 5px;
      padding: 100rem 5% 50rem;

      > button {
        margin: 50rem 0 0 0;
      }
    }
  }
`;
