import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

// import ig from 'assets/img/galleryPicker/ig.png';
import logo from 'assets/img/mirindaLogo.png';
import Button from 'components/Button/Button';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import { API } from 'services/api';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as IconAI } from 'svgs/ai.svg';
import { ReactComponent as IconManual } from 'svgs/manual.svg';
import GTM from 'u9/utils/gtm';
import { SPA_ROUTES } from 'utils/routes';
import { fadeMotionProps, pageMotionProps } from 'utils/styles/animations';

import * as Styled from './styles/p6-GalleryPicker.styles';

export interface GalleryPickerProps {
  copy: GlobalCopy['galleryPicker'];
  userCode: string;
}

const defaultProps: Partial<GalleryPickerProps> = {};

const GalleryPicker: React.FC<GalleryPickerProps> = ({ copy, userCode }) => {
  const {
    setCurrentPage,
    setImageSource,
    setIsUserSelected,
    setImageUrl,
    allUrls,
    lastSelectedUrls,
    setLastSelectedUrls,
    playProcessingExitAnim,
    setRefreshCanCount,
    setAiError,
  } = useGlobalStore();
  const { theme } = useThemeStore();
  const [isIGselected] = useState(false);
  const [isAIselected, setIsAIselected] = useState(false);

  const handleConfirm = () => {
    setTimeout(() => {
      if (isIGselected) {
        if (userCode) {
          if (isAIselected) {
            GTM.trackEvent('Create More', 'Click', 'Confirm Local Gallery');
            const getMultipleRandom = (arr, num) => {
              const shuffled = [...arr].sort(() => 0.5 - Math.random());
              return shuffled.slice(0, num);
            };
            console.log(lastSelectedUrls);
            const newSetOfUrls = getMultipleRandom(
              allUrls,
              allUrls.length > 9 ? 9 : allUrls.length
            );
            console.log(newSetOfUrls);
            API.instance.generateFromLinks(
              newSetOfUrls,
              setAiError,
              setCurrentPage,
              setImageUrl,
              playProcessingExitAnim,
              setLastSelectedUrls
            );
          } else {
            GTM.trackEvent(
              'Create More - Instagram',
              'Click',
              'Confirm Instagram'
            );
          }

          setImageSource('IG');
          setIsUserSelected(!isAIselected);
          setCurrentPage(
            isAIselected ? SPA_ROUTES.PROCESSING : SPA_ROUTES.FAKEGALLERY
          );
        } else {
          setCurrentPage(SPA_ROUTES.SOURCESELECTION);
        }
      } else {
        GTM.trackEvent(
          'Create More - Local Gallery',
          'Click',
          'Confirm Local Gallery'
        );
        setImageSource('LOCAL');
      }
      setRefreshCanCount(0);
    }, 400);
    // wait for onTap animation to complete
  };

  useEffect(() => {
    setIsAIselected(isIGselected);
  }, [isIGselected]);

  useEffect(() => {
    window.localStorage.setItem('manualSelectionIG', 'false');
    GTM.trackPage('/', 'Create More');
  }, []);

  return (
    <motion.div {...fadeMotionProps}>
      <Styled.WrapperGalleryPicker>
        <motion.img src={logo} alt="" {...pageMotionProps} />
        {/* <Styled.MainSelect key="mainSelect" {...fadeMotionProps}>
          <Button
            label={copy.cta1}
            imgUrl={ig}
            whiteBg={true}
            onClick={() => setIsIGselected(true)}
            className={isIGselected ? 'selected' : 'unselected'}
            hasOwnAnimation={true}
          />
          <Button
            label={copy.cta2}
            imgUrl={theme.ctas?.galleryPickerGallery}
            whiteBg={true}
            onClick={() => setIsIGselected(false)}
            className={isIGselected ? 'unselected' : 'selected'}
            hasOwnAnimation={true}
          />
        </Styled.MainSelect> */}
        <motion.h1
          dangerouslySetInnerHTML={{ __html: copy.title }}
          {...pageMotionProps}
        />

        <Styled.SecondarySelect
          id={!isIGselected ? 'isNotIGselected' : undefined}
          key="secondarySelect"
          {...fadeMotionProps}
        >
          {isIGselected && (
            <Button
              label={copy.select1}
              imgUrl={theme.ctas?.galleryPickerSelectionAI}
              whiteBg={true}
              vertical={true}
              onClick={() => {
                window.localStorage.setItem('manualSelectionIG', 'false');
                GTM.trackEvent(
                  'Create More - Instagram',
                  'Click',
                  isAIselected ? 'AI Selection' : 'Manual Selection Instagram'
                );
                setIsAIselected(true);
              }}
              className={isAIselected ? 'selected' : 'unselected'}
              hasOwnAnimation={true}
            >
              <IconAI />
            </Button>
          )}
          <Styled.HiddenInput onClickCapture={handleConfirm}>
            <ImageUpload permissionsGranted={!isIGselected} />
            <Button
              label={copy.select2}
              imgUrl={theme.ctas?.galleryPickerSelectionManual}
              whiteBg={true}
              vertical={true}
              onClick={() => {
                window.localStorage.setItem('manualSelectionIG', 'true');
                setIsAIselected(false);
                GTM.trackEvent(
                  'Create More - Local Gallery',
                  'Click',
                  'Manual Selection Local Gallery'
                );
              }}
              className={isAIselected ? 'unselected' : 'selected'}
              hasOwnAnimation={true}
            >
              <IconManual />
            </Button>
          </Styled.HiddenInput>
        </Styled.SecondarySelect>

        {/* <Styled.HiddenInput onClickCapture={handleConfirm}>
          <ImageUpload permissionsGranted={!isIGselected} />
          <Button
            label={copy.confirm}
            imgUrl={theme.ctas?.galleryPickerGo}
            whiteBg={true}
          />
        </Styled.HiddenInput> */}
      </Styled.WrapperGalleryPicker>
    </motion.div>
  );
};

GalleryPicker.defaultProps = defaultProps;

export default GalleryPicker;
