/* eslint-disable react/no-unescaped-entities */
import React from 'react';

/**
 *
 * If necessary to update content, please, use this tool to convert doc to HTML and keep the same structure and avoid re-work: https://cloudconvert.com/docx-to-html
 */

const TermsEN: React.FC = () => {
  return (
    <article>
      <h3
        className="MsoNormal"
        style={{
          marginBottom: '8pt',
          textAlign: 'center',
          lineHeight: 'normal',
        }}
      >
        <span lang="EN-AU" style={{ textTransform: 'uppercase' }}>
          Mirinda® AI CAN Flavour Generator
        </span>
      </h3>
      <p
        className="MsoNormal"
        style={{
          marginBottom: '0in',
          textAlign: 'center',
          lineHeight: 'normal',
          fontSize: '44rem',
        }}
      >
        <span lang="EN-AU">
          PLEASE READ THE TERMS CAREFULLY BEFORE USING THIS SERVICE
        </span>
      </p>
      <section>
        <div className="content">
          <p
            className="MsoNormal"
            style={{
              marginBottom: '0in',

              lineHeight: 'normal',
            }}
          >
            <span lang="EN-AU" style={{ fontSize: '12pt' }}>
              &nbsp;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: '0in',

              lineHeight: 'normal',
            }}
          >
            <span lang="EN-AU">
              Use of the Mirinda “There’s No Flavour Like Your Flavour” AI Can
              flavour generator service application (the "Site"), available at
              url{' '}
            </span>
            <span lang="EN-GB">MirindaFlavourGenerator.com, </span>
            <span lang="EN-AU">
              constitutes acceptance of these terms ("Terms") and as such may be
              revised from time to time by PepsiCo, Inc. (“PepsiCo” or “we” or
              “us” or “our,” as the case may be). These Terms are binding
              between the User ("User” or “you" or “your” or “their,” as the
              case may be) and PepsiCo governing the use of the Site&nbsp;and
              participation with this service (the "Program").&nbsp;
            </span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: '0in',

              lineHeight: 'normal',
            }}
          >
            <span lang="EN-AU">&nbsp;</span>
          </p>
          <p
            className="MsoNormal"
            style={{
              marginBottom: '0in',

              lineHeight: 'normal',
            }}
          >
            <span lang="EN-AU">
              By using this Site, User acknowledges and agrees to the
              Terms.&nbsp; If User does not agree to the Terms, User must not
              participate in the Program.
            </span>
          </p>
          <p className="MsoNormal" style={{ textAlign: 'justify' }}>
            <b>
              <span lang="EN-AU" style={{ fontFamily: '"Arial", sans-serif' }}>
                &nbsp;
              </span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpFirst"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Introduction and background</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              1.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              These terms of use are in relation to the Mirinda AI Can flavour
              generator application (and any and all functionality, materials
              and content in connection with it) (<b>Site</b>) provided by
              PepsiCo and any of its related corporate affiliates (collectively,{' '}
              <b>we</b>,<b>us</b>, <b>our</b>).
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              1.2.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              Certain functions of the Site may be subject to additional terms
              and conditions (including terms and conditions of other
              promotional partners in clause{' '}
            </span>
            <span lang="EN-AU">1</span>
            <span lang="EN-AU">
              ) as may be indicated in the Site. These terms of use and any and
              all additional terms and conditions constitute the <b>Terms</b>.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              1.3.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              By accessing or using the Site, you are deemed to have accepted
              these Terms. If you do not agree with these Terms, you must not
              use the Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Using the Site</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              To the fullest extent permitted by law, the Site is provided on an
              ‘as is’ and ‘as available’ basis without any warranty of any kind.
              We may suspend or terminate the Site (or any component of the
              Site) at any time.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.2.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              A compatible smartphone with internet connectivity is required to
              access the functionality of the Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.3.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-GB">
              The Site supports only Safari (iOS) or Chrome (Android) browsers.
              In all other instances, users will receive an error message
              stating that other browsers are not supported.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.4.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            {/* <span lang="EN-AU"> */}
            <span>This site is protected by reCAPTCHA and the Google </span>
            <a href="https://nam12.safelinks.protection.outlook.com/?url=https%3A%2F%2Fpolicies.google.com%2Fprivacy&data=05%7C01%7CMurali.Pasupulati%40pepsico.com%7C31657c53770e4fa7160f08db677002b1%7C42cc3295cd0e449cb98e5ce5b560c1d3%7C0%7C0%7C638217503299730429%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2Fahire1E%2B7RJPXylSfpx9sEkd233eP0XAMy%2FVaY5DdM%3D&reserved=0">
              <span lang="EN-IE">Privacy Policy</span>
            </a>
            {/* </span> */}
            <span className="xcontentpasted1">
              <b>
                <span
                  lang="EN-AU"
                  style={{
                    fontSize: '12pt',
                    lineHeight: '107%',
                    color: 'black',
                    background: 'white',
                  }}
                >
                  &nbsp;
                </span>
              </b>
            </span>
            <span lang="EN-AU">and</span>
            <span className="xcontentpasted1">
              <b>
                <span
                  lang="EN-AU"
                  style={{
                    fontSize: '12pt',
                    lineHeight: '107%',
                    color: 'black',
                    background: 'white',
                  }}
                >
                  &nbsp;
                </span>
              </b>
            </span>
            {/* <span lang="EN-AU"> */}
            <a
              href="https://nam12.safelinks.protection.outlook.com/?url=https%3A%2F%2Fpolicies.google.com%2Fterms&data=05%7C01%7Cdaniel.murphy%40pepsico.com%7C4ed3f23d007f4f2dacee08db4d53d4fc%7C42cc3295cd0e449cb98e5ce5b560c1d3%7C0%7C0%7C638188794688266819%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=d7zDUnUK7%2BuR09jpQcFFZF0m%2BjzyiYODnvEjdRkmSLI%3D&reserved=0"
              target="_blank"
              title="https://protect-eu.mimecast.com/s/ilxacgyj6hwyygodh7kx4k?domain=policies.google.com"
              rel="noreferrer"
            >
              <span>Terms of Service</span>
            </a>
            {/* </span> */}
            <span className="xcontentpasted1">
              <b>
                <span
                  lang="EN-AU"
                  style={{
                    fontSize: '12pt',
                    lineHeight: '107%',
                    color: 'black',
                    background: 'white',
                  }}
                >
                  &nbsp;
                </span>
              </b>
            </span>
            <span lang="EN-AU"> apply.</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.5.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              User can upload photos from their phone photo gallery to
              participate in the Program
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.6.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              A minimum of three (3) photos with a maximum of nine (9) photos
              (the “Photos”) for any manual selection is required to generate a
              successful AI Can image design (“Artwork”) for the Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.7.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-GB">
              Once the Artwork has been processed, it is displayed to User as
              Artwork. Users can download a JPEG image of Artwork, GIF of
              Artwork, or use the native sharing functionality to share Artwork
              with others on other platforms.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              2.8.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-GB">
              Artwork can be refreshed up to a maximum of three (3) times only,
              thereafter, requiring the User to recommence a new activity for
              further Artwork creation again.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Your obligations</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              3.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">You must not:</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              a.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              use the Site for any unlawful purpose or in in a manner that is
              inconsistent with our reasonably expected use of the Site;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              b.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              use the Site in any manner except as expressly provided for by
              these Terms;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              c.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              reverse engineer, decompile, disassemble, damage, disable,
              overburden, impair the Site;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              d.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              interfere with or disrupt the Site or the servers or networks that
              host the Site, use data mining, robots, screen scraping or similar
              data gathering and extraction tools on the Site, or interfere with
              security-related or other features of the Site; or
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              e.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              attempt to do any of the actions stated in this clause 3.1.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Privacy</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              4.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              We may collect, hold, use and/or disclose some personal
              information which will always be done in accordance with our
              privacy collection notice and privacy policy as may be varied from
              time to time. You can access our current global privacy policy at
              <u>https://www.pepsico.com/legal/privacy</u>
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Intellectual property</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              5.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              You represent and warrant that you have the right and/or have
              obtained any necessary permissions to use the Photos as
              anticipated under this Program and that such Photos do not
              infringe the rights of any third party.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              5.2.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              As between you and us, we own or have a licence to all
              intellectual property rights in connection with the functionality
              of the Site and any Artwork. &nbsp;Further, as between you and us,
              we own the Artwork itself and any digital or physical copies of
              the Artwork.&nbsp; User is granted a worldwide non-sublicensable
              licence only to the Artwork for personal use and non-commercial
              purposes only, in perpetuity. &nbsp;Further, we reserve the right
              to require that you to delete any such Artwork upon reasonable
              determination by us that it may expose us, including our business
              partners to a charge of potential copyright infringement.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              5.3.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              Nothing in these Terms gives you any right, title or interest in
              the Artwork or in the intellectual property rights in connection
              with the Site or Artwork generated by the Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              5.4.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              You acknowledge that all rights in the Mirinda name and logo (the
              “Mirinda Mark”) are&nbsp; owned by us and agree that you will not
              use the Mirinda Mark for any purpose whatsoever.&nbsp; Further,
              you will not do anything that would tend to disparage the Mirinda
              brand.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              5.5.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">You must not:</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              a.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              do anything which may jeopardise our right, title or interest in
              any intellectual property owned by or licensed to us; or
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              b.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              reproduce, transfer, or supply any aspect of the Site to a third
              party except with our prior written consent.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Liability</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              6.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              Neither we nor any party will be liable for any claim, liability,
              loss, damage, cost (including legal cost) or expense (<b>Loss</b>)
              which is suffered or sustained (whether or not arising from any
              person’s negligence) by any person in connection with this Site,
              except for any liability which cannot be excluded by law (in which
              case that liability is limited to the minimum amount allowable by
              law).
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              6.2.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              You indemnify and keep us indemnified against any Loss that we
              suffer or incur as a direct or indirect result of your:
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              a.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              negligent or willful act or omission with respect to your use of
              the Site (or any part of it);
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              b.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">breach of these Terms by you; and</span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '53.7pt',

              textIndent: '-17.85pt',
            }}
          >
            <span lang="EN-AU">
              c.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              infringement of any intellectual property or other right of any
              person or entity in connection with your breach of these Terms or
              your negligent or unlawful acts or omissions.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              6.3.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              We assume no responsibility and accept no liability whatsoever for
              the condition or content of third party websites that may be
              linked to or accessed from the Site. Except as we may specifically
              direct otherwise, we do not authorise the content of those third
              party websites.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                7.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">Termination</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              7.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              We may suspend (for a period of time or indefinitely, as
              determined at our discretion) or terminate the Site at any time
              without notice for any reason including but not limited to if we
              reasonably suspect fraudulent use, misuse or abuse of the Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '0.25in',

              textIndent: '-0.25in',
            }}
          >
            <b>
              <span lang="EN-AU">
                8.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span lang="EN-AU">General</span>
            </b>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              8.1.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              We may vary these Terms (including when we add, change or remove
              any feature or process in the Site or because of changes in law)
              at any time by publishing an updated version of the Terms to our
              website without notice to you. If there is a material change to
              your rights as a result of the variation to Terms, we will
              endeavour but are not obligated to notify you of the updated Terms
              prior to the variation taking effect. By continuing to use the
              Site, you are deemed to have accepted the updated Terms.&nbsp; If
              you no longer agree to the Terms, you should stop all use of the
              Site.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              8.2.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              These Terms are governed by and construed in accordance with the
              laws of Ireland.&nbsp; The parties submit to the non-exclusive
              jurisdiction of the courts of Ireland.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              8.3.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              These Terms constitute the entire agreement between you and us on
              the subject matter.&nbsp; The only enforceable obligations and
              liabilities against us in relation to the subject matter are those
              that arise out of the provisions contained in these Terms.&nbsp;
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpMiddle"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              8.4.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              If any provision of these Terms or any part of a provision of
              these Terms is in any way unenforceable, invalid or illegal, it is
              to be read down so as to be enforceable, valid and legal. If this
              is not possible, the clause (or where possible, the offending
              part) is to be severed from these Terms without affecting the
              enforceability, validity or legality of these Terms and the
              remaining clauses (or parts of those clauses) which will continue
              in full force and effect.
            </span>
          </p>
          <p
            className="MsoListParagraphCxSpLast"
            style={{
              marginLeft: '23.85pt',
            }}
          >
            <span lang="EN-AU">
              8.5.
              <span style={{ font: '7pt "Times New Roman"' }}>
                &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span lang="EN-AU">
              No failure or delay by us to exercise any right or remedy provided
              under these Terms or by law shall constitute a waiver of that or
              any other right or remedy, nor shall it preclude or restrict the
              further exercise of that or any other right or remedy. No single
              or partial exercise of such right or remedy shall preclude or
              restrict the further exercise of that or any other right or
              remedy.
            </span>
          </p>
        </div>
        <div
          style={{
            height: '50px',
          }}
        ></div>
      </section>
    </article>
  );
};

export default TermsEN;
