import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export interface WrapperProps {
  $twoLineTitle?: boolean;
  $threeLineTitle?: boolean;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  z-index: ${layout.zIndex.modals};

  .heading {
    ${({ theme: { locale } }) => setTypography('heading3', locale)}
    font-size: 90rem;
    ${({ theme }) => `color: ${theme.global.colors.font};`}
    text-shadow: none;
  }

  button {
    width: ${({ theme: { locale } }) => locale !== 'en' && '85%'};
  }

  > div,
  > section {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  > div {
    background-color: ${colors.black};
    opacity: 0.3;
    height: 100%;
    width: 100%;
  }

  > section {
    background-color: ${colors.white};
    border-radius: 5px;
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 90rem 20rem;
    ${({ theme: { locale } }) => setTypography('bodyModal', locale)}
    font-size: ${({ theme: { locale } }) => locale === 'pk' && '45rem'};
    color: ${colors.orange};

    > img:first-of-type {
      margin: 30rem auto 50rem;
      height: 80rem;

      ${({ $twoLineTitle }) =>
        $twoLineTitle &&
        css`
          height: 180rem;
        `}

      ${({ $threeLineTitle }) =>
        $threeLineTitle &&
        css`
          height: 280rem;
        `}
    }

    p {
      padding: 0 10% 2rem;
      text-align: center;
      margin-bottom: 50rem;
    }

    > button {
      margin-bottom: 30rem;

      > img {
        min-height: 30%;
      }
    }

    > button:nth-of-type(2) {
      background-color: ${colors.supernova};
    }
  }
`;
