import React, { useEffect, useState } from 'react';

import ImageLazy from 'components/ImageLazy/ImageLazy';

import * as Styled from './ImageGallery.styles';

export interface ImageGalleryProps {
  files: any[];
  handleSetSelectedCount: (boolean) => boolean;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  files,
  handleSetSelectedCount,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Styled.Wrapper>
      <div>
        {files.map(file => (
          <ImageLazy
            key={file.id}
            file={file}
            scrollPosition={scrollPosition}
            handleSetSelectedCount={handleSetSelectedCount}
          />
        ))}
      </div>
    </Styled.Wrapper>
  );
};

export default ImageGallery;
