import { AnimatePresence } from 'framer-motion';
import * as React from 'react';

import { ReactComponent as SvgArrow } from 'svgs/arrow.svg';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './BackButton.styles';

interface BackButtonProps {
  onClick: (any) => void;
  isVisible: boolean;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, isVisible }) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <Styled.Wrapper onClick={onClick} {...fadeMotionProps}>
          <SvgArrow />
        </Styled.Wrapper>
      )}
    </AnimatePresence>
  );
};

BackButton.displayName = 'BackButton';

export default React.memo(BackButton);
