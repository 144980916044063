import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const WrapperGalleryPicker = styled(Wrapper)`
  justify-content: start;
  gap: 120rem;
  height: ${setVh(100)};
  width: 100vw;
  user-select: none;

  > img:first-of-type {
    height: 200rem;
    width: auto;
    margin-top: 35rem;
  }

  > h1 {
    color: ${colors.white};
    text-align: center;
    padding: 30rem;
    text-shadow: -7px 8px 10px rgba(0, 0, 0, 0.25);
    ${({ theme: { locale } }) => setTypography('heading4', locale)}
  }

  button {
    font-size: ${({ theme: { locale } }) =>
      locale === 'pk' ? '50rem' : '65rem'};
    line-height: ${({ theme: { locale } }) =>
      locale === 'pk' ? '65rem' : '75rem'};
  }

  .unselected {
    transform: scale(0.9);
    z-index: 3;

    > img,
    > svg {
      transform: scale(0.75);
      opacity: 0.5;
    }
  }

  .selected {
    transform: scale(1.05);
    z-index: 5;
    box-shadow: -5px 19px 44px rgba(99, 47, 0, 0.68);

    > img {
      transform: scale(0.8);
      opacity: 1;
    }

    > svg {
      transform: scale(1.4);
      opacity: 1;
    }
  }

  #isNotIGselected {
    > button:last-of-type {
      transform: translateX(42%);
    }
  }
`;

export const MainSelect = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 10%;
  margin-bottom: 10rem;

  > button {
    position: absolute;
    transition: transform 0.3s ease-in-out;

    > img {
      transition: opacity 0.3s ease-in-out;
    }
  }

  > button:first-of-type {
    left: 8%;
    width: 45vw;

    &.selected {
      transform: scale(0.95);
      > img {
        transform: scale(0.75);
      }
    }

    &.unselected {
      transform: scale(0.8) translateX(-10%);
    }
  }

  > button:last-of-type {
    right: 8%;
    width: 52vw;

    &.selected {
      transform: scale(0.95);
      > img {
        transform: scale(0.75);
      }
    }

    &.unselected {
      transform: scale(0.8) translateX(10%);
    }
  }

  > .selected {
    box-shadow: 0px 4px 35px rgba(134, 64, 0, 0.57);
  }
`;

export const SecondarySelect = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 30%;
  margin-bottom: 30rem;
  z-index: 1;

  > button {
    position: absolute;
    height: ${setVh(28)};
    width: 40vw;
    transition: transform 0.3s ease-in-out;

    > svg,
    > img {
      transition: opacity 0.3s ease-in-out;
    }

    > img {
      width: 80%;
      height: auto;
    }
  }

  > button:first-of-type {
    left: 13%;
  }

  > button:last-of-type {
    right: 13%;
  }
`;

export const HiddenInput = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 30rem;

  > button {
    position: absolute;
    height: 52vw;
    width: 46vw;
    transition: transform 0.3s ease-in-out;

    > svg,
    > img {
      transition: opacity 0.3s ease-in-out;
    }

    > svg {
      padding-top: 2vw;
    }
    > img {
      width: 70%;
      height: auto;
    }
  }

  > input {
    z-index: 6;
    opacity: 0;
    visibility: none;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;

    &.disabled {
      pointer-events: none;
    }
  }
`;
