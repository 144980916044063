import styled, { css } from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { rotateCan } from 'utils/styles/animations';
import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const WrapperProcessing = styled(Wrapper)<{
  $processingExitAnim: boolean;
}>`
  user-select: none;
  > img {
    position: absolute;
    top: 200rem;
    height: 330rem;
    width: auto;
  }

  > section {
    position: relative;
    color: ${colors.white};
    ${({ theme: { locale } }) => setTypography('processing', locale)}
    transform: scale(1);
    opacity: 1;
    transition: all 0.3s ease-in-out;
    width: 400rem;
    height: 630rem;
    animation: ${rotateCan} 1.1s linear infinite;
    animation-direction: alternate-reverse;

    ${({ $processingExitAnim }) =>
      $processingExitAnim &&
      css`
        transform: scale(0) !important;
        opacity: 0;
      `};
  }
`;
