/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import TermsEN from './TermsEN';

import * as Styled from './TermsAndConditions.styles';

export interface TermsAndConditionsProps {
  locale: string;
}

const defaultProps: Partial<TermsAndConditionsProps> = {
  locale: 'en',
};

/**
 *
 * If necessary to update content, please, use this tool to convert doc to HTML and keep the same structure and avoid re-work: https://cloudconvert.com/docx-to-html
 */

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ locale }) => {
  return (
    <Styled.Wrapper>
      {locale === 'en' ? (
        <TermsEN />
      ) : locale === 'es' ? (
        <TermsEN />
      ) : (
        <TermsEN />
      )}
    </Styled.Wrapper>
  );
};

TermsAndConditions.defaultProps = defaultProps;

export default TermsAndConditions;
