import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: ${layout.zIndex.modals};

  > .dimBg {
    position: absolute;
    background-color: ${colors.black};
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
  }

  > .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: auto;
    max-height: 90%;
    background-color: ${colors.white};
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    border-radius: 5px;
    padding: 100rem 5% 50rem;

    > button {
      margin: 50rem 0 0 0;
    }
  }
`;
