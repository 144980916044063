import React, { useState } from 'react';
import {
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component';

import placeholderImg from 'assets/img/image_placeholder.png';
import { ReactComponent as SvgSelected } from 'svgs/selected.svg';

import * as Styled from './ImageLazy.styles';

import 'react-lazy-load-image-component/src/effects/blur.css';

export interface ImageLazyProps {
  file: any;
  scrollPosition: { x: number; y: number };
  handleSetSelectedCount: (boolean) => boolean;
}

const defaultProps: Partial<ImageLazyProps> = {};

const ImageLazy: React.FC<ImageLazyProps> = ({
  file,
  scrollPosition,
  handleSetSelectedCount,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleOnClick = () => {
    const isSelectedMaxCount = handleSetSelectedCount(!isSelected);

    if (!isSelectedMaxCount) {
      setIsSelected(!isSelected);
      file.isSelected = !isSelected;
    }
  };

  return (
    <Styled.Wrapper
      $isSelected={isSelected}
      $scaleReversed={file.scaleReversed}
      onClick={handleOnClick}
      key={file.id}
    >
      <LazyLoadImage
        placeholderSrc={placeholderImg}
        src={file.url}
        loading="lazy"
        effect="blur"
        alt=""
        scrollPosition={scrollPosition}
      />
      <SvgSelected />
    </Styled.Wrapper>
  );
};

ImageLazy.defaultProps = defaultProps;

export default trackWindowScroll(ImageLazy);
