import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import logo from 'assets/img/mirindaLogo.png';
import Liquid from 'components/Liquid/Liquid';
import Modal from 'components/Modal/Modal';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import GTM from 'u9/utils/gtm';
import {
  DEFAULT_PROCESSING_TIME_TO_90_PERCENT_IN_MS,
  MAX_PROCESSING_TIME_IN_MS,
} from 'utils/constants';
import { SPA_ROUTES } from 'utils/routes';
import { processingCanMotionProps } from 'utils/styles/animations';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './styles/p7-Processing.styles';

import { AiError } from 'constants/enums';

export interface ProcessingProps {
  percentLoad?: number;
  copyModals: GlobalCopy['modals'];
}

const defaultProps: Partial<ProcessingProps> = {};

const Processing: React.FC<ProcessingProps> = ({ percentLoad, copyModals }) => {
  const { processingExitAnim, setCurrentPage, showAiError, setAiError } =
    useGlobalStore();
  const [percent, setPercent] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const mountedRef = useRef(true);

  useEffect(() => {
    GTM.trackPage('/', 'Processing');
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (mountedRef.current && percent < 90)
        setPercent(prev => (prev < 100 ? prev + 5 : 100));
      // show percentage in 5%'s steps
    }, DEFAULT_PROCESSING_TIME_TO_90_PERCENT_IN_MS / 20);
  }, [percent]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (showAiError === AiError.none) setAiError(AiError.oops);
    }, MAX_PROCESSING_TIME_IN_MS);

    return () => {
      setPercent(0);
      setAiError(AiError.none);
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAiError !== AiError.none) setShowErrorModal(true);

    return () => {
      setShowErrorModal(false);
    };
  }, [showAiError]);

  useEffect(() => {
    // show 100% before entering Artwork page
    if (
      mountedRef.current &&
      percent >= 10 &&
      !(percent % 10) &&
      processingExitAnim
    )
      setPercent(100);
  }, [processingExitAnim, percent]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <motion.div>
      <Styled.WrapperProcessing $processingExitAnim={processingExitAnim}>
        <AnimatePresence>
          <motion.img
            key="processingLogo"
            src={logo}
            alt=""
            {...fadeMotionProps}
          />
          <motion.section key="processingCan" {...processingCanMotionProps}>
            <Liquid
              number={percentLoad ? Math.floor(percentLoad * 100) : percent}
            />
          </motion.section>
        </AnimatePresence>
        <AnimatePresence>
          {showErrorModal && (
            <Modal
              src={{ forceCopy: true }}
              copy={{
                title:
                  showAiError === AiError.oops
                    ? copyModals.oops.title
                    : copyModals.traffic.title,
                body:
                  showAiError === AiError.oops
                    ? copyModals.oops.body
                    : copyModals.traffic.body,
                label1:
                  showAiError === AiError.oops
                    ? copyModals.oops.label1
                    : copyModals.traffic.label1,
              }}
              onClick1={() => setCurrentPage(SPA_ROUTES.LANDING)}
              props={fadeMotionProps}
            />
          )}
        </AnimatePresence>
      </Styled.WrapperProcessing>
    </motion.div>
  );
};

Processing.defaultProps = defaultProps;

export default Processing;
