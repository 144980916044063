import React, { useCallback, useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';

import ImageGallery from 'components/ImageGallery/ImageGallery';
import { API, proceedToProcessingPage } from 'services/api';
import { useGlobalStore } from 'store';
import { MAX_SELECTED, MIN_SELECTED } from 'utils/constants';
import {
  getOrientation,
  rotationNeededBasedOnExifData,
} from 'utils/imageOrientation';
import { SPA_ROUTES } from 'utils/routes';

import { generateFromImagesMock } from './mockdata';

import * as Styled from './ImageUpload.styles';

export interface ImageUploadProps {
  copy?: any;
  permissionsGranted?: boolean;
  hasUserChosenImages?: boolean;
  setHasUserChosenImages?: (arg: boolean) => void;
  isFakeGallery?: boolean;
  imagesUrls?: string[];
}

const defaultProps: Partial<ImageUploadProps> = {};

const ImageUpload: React.FC<ImageUploadProps> = ({
  copy,
  permissionsGranted,
  hasUserChosenImages,
  setHasUserChosenImages,
  isFakeGallery,
  imagesUrls = [],
}) => {
  const {
    setCurrentPage,
    setImageUrl,
    setShowAtLeast3ImagesError,
    setLastSelectedFiles,
    setLastSelectedUrls,
    playProcessingExitAnim,
    playProcessingPreEnterAnim,
    setAiError,
  } = useGlobalStore();
  const [files, setFiles] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  // const [errorInfo, setErrorInfo] = useState('');
  const [filesToResize, setFilesToResize] = useState([]);
  const [expectedNumberOfFiles, setExpectedNumberOfFiles] = useState(0);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const isResizeEnabled = true;
  const inputUploadRef = useRef<HTMLInputElement>(null);

  const imgOrientSupported = CSS.supports('image-orientation', 'from-image');

  const isFileImage = (file: File) => {
    return file['type'].split('/')[0] === 'image';
  };

  const onUploadFiles = async e => {
    console.log('onUploadFiles', e.target.files);
    console.table(e.target.files);
    const filteredFiles = Object.keys(e.target.files).filter(
      key => !e.target.files[key].type.includes('video')
    );
    if (filteredFiles.length < 3) {
      setShowAtLeast3ImagesError(true);
      return;
    }

    const tempArr = [];

    try {
      if (e.target.files && e.target.files.length > 0) {
        if (isResizeEnabled) {
          setFilesToResize([...e.target.files]);
        } else {
          [...e.target.files].map(file => {
            const { scaleReversed } = fixImageOrientation(file);
            tempArr.push(file);
            if (isFileImage(file)) {
              handleFile(file, scaleReversed);
              setExpectedNumberOfFiles(prev => prev + 1);
            }
          });
        }

        // setErrorInfo('');
      } else {
        // TO DO: connect OOPS error modal
        // setErrorInfo('Upload error');
        // setTimeout(() => setErrorInfo(''), 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleFile = (file, scaleReversed, isFromIG = false) => {
    const newFile = {
      id: uuidv4(),
      url: isFromIG ? file : URL.createObjectURL(file),
      file, // TODO if isFromIG
      isSelected: isFakeGallery ? false : true,
      scaleReversed,
    };

    setFiles(oldArray => [...oldArray, newFile]);
  };

  const fixImageOrientation = useCallback(
    (file: File) => {
      let rotation = 0;
      let scaleReversed = false;

      if (!imgOrientSupported) {
        getOrientation(file, orientation => {
          if (orientation > 0) {
            rotation = rotationNeededBasedOnExifData[orientation.toString()];
          }
          scaleReversed = orientation === 3;
        });
      }

      return { rotation, scaleReversed };
    },
    [imgOrientSupported]
  );

  const clearSelection = useCallback(() => {
    if (inputUploadRef.current) inputUploadRef.current.value = '';
    setFiles([]);
    setFilesToResize([]);
    setSelectedCount(0);
    setExpectedNumberOfFiles(0);
    setIsUploadCompleted(false);
  }, []);

  const resizeFile = useCallback(
    (file: File) => {
      const { rotation, scaleReversed } = fixImageOrientation(file);

      Resizer.imageFileResizer(
        file,
        500, // maxWidth
        500, // maxHeight
        'JPEG', //  JPEG, PNG or WEBP
        50, // quality, used for compression, 0-100
        rotation,
        uri => {
          handleFile(uri, scaleReversed);
        },
        'file' // base64, blob or file
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fixImageOrientation]
  );

  useEffect(() => {
    if (expectedNumberOfFiles > 0) {
      // console.log('count', expectedNumberOfFiles, files.length);
      setIsUploadCompleted(expectedNumberOfFiles === files.length);
    }
  }, [expectedNumberOfFiles, files]);

  useEffect(() => {
    if (filesToResize.length > 0) {
      filesToResize.map(file => {
        if (isFileImage(file)) {
          setExpectedNumberOfFiles(prev => prev + 1);
          resizeFile(file);
        }
      });
      setFilesToResize([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToResize]);

  useEffect(() => {
    if (imagesUrls.length >= MIN_SELECTED) {
      imagesUrls.map(url => {
        handleFile(url['url'], false, true);
      });
      console.log('imagesUrls to files', files);
      setIsUploadCompleted(imagesUrls.length === files.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesUrls]);

  useEffect(() => {
    async function generateFromLinks(selectedImagesUrls: any[]) {
      await API.instance.generateFromLinks(
        selectedImagesUrls,
        setAiError,
        setCurrentPage,
        setImageUrl,
        playProcessingExitAnim,
        setLastSelectedUrls
      );
    }
    if (isUploadCompleted || hasUserChosenImages) {
      if (
        (!isFakeGallery && files.length >= MIN_SELECTED) ||
        (isFakeGallery && hasUserChosenImages && files.length >= MIN_SELECTED)
      ) {
        let selectedImages = files.filter(file => file.isSelected);
        if (selectedImages.length > 9) {
          selectedImages = files.slice(0, MAX_SELECTED);
        }

        if (imagesUrls.length) {
          if (selectedImages.length >= MIN_SELECTED) {
            const selectedImagesUrls = selectedImages.map(file => ({
              url: file.url,
              caption: file.caption || '',
            }));
            console.error('selectedImagesUrls', selectedImagesUrls);

            generateFromLinks(selectedImagesUrls);
            proceedToProcessingPage(playProcessingPreEnterAnim, setCurrentPage);
          } else {
            if (!isFakeGallery) {
              clearSelection();
            }
            setShowAtLeast3ImagesError(true);
            return;
          }
        } else {
          const formData = new FormData();

          selectedImages.map(file => {
            formData.append('uploaded_images', file.file);
            formData.append('image_descriptions', ' ');
          });

          formData.append('flavour', 'Mirinda');

          if (!process.env.IS_DEBUG) {
            API.instance.generateFromImages(
              formData,
              setAiError,
              setCurrentPage,
              setImageUrl,
              playProcessingExitAnim
            );

            proceedToProcessingPage(playProcessingPreEnterAnim, setCurrentPage);
          } else {
            setImageUrl(generateFromImagesMock.textureUrl);
            playProcessingExitAnim(true);
            setTimeout(() => {
              playProcessingExitAnim(false);
              setCurrentPage(SPA_ROUTES.ARTWORK);
            }, 500);
          }

          setLastSelectedFiles(formData);
        }
      } else {
        if (!isFakeGallery && files.length) {
          clearSelection();
          setShowAtLeast3ImagesError(true);
          return;
        }
      }
      if (setHasUserChosenImages) setHasUserChosenImages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadCompleted, hasUserChosenImages]);

  const handleSetSelectedCount = isSelected => {
    if (isSelected && selectedCount === MAX_SELECTED) return true;

    isSelected
      ? setSelectedCount(prev => prev + 1)
      : setSelectedCount(prev => prev - 1);
  };

  return (
    <>
      {copy ? (
        <Styled.Wrapper $isResizeEnabled={isResizeEnabled}>
          {/* LEFT FOR DEBUG */}
          <div className="uploadBtn">
            <button>{copy.ctaUpload}</button>
            <input
              ref={inputUploadRef}
              type="file"
              onChange={onUploadFiles}
              multiple
              accept="image/png, image/jpeg, image/jpg"
            />
          </div>

          {files && (
            <ImageGallery
              files={files}
              handleSetSelectedCount={handleSetSelectedCount}
            />
          )}

          <div className="bottomWrapper">
            <p>{`${copy.selected} (${selectedCount}/${MAX_SELECTED})`}</p>
            <p dangerouslySetInnerHTML={{ __html: copy.description }} />
          </div>
        </Styled.Wrapper>
      ) : (
        <input
          ref={inputUploadRef}
          className={!permissionsGranted ? 'disabled' : undefined}
          type="file"
          onChange={onUploadFiles}
          multiple
          accept="image/png, image/jpeg, image/jpg"
        />
      )}
    </>
  );
};

ImageUpload.defaultProps = defaultProps;

export default ImageUpload;
