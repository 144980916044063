import styled from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setTypography, setVh } from 'utils/styles/mixins';
import { colors, layout } from 'utils/styles/theme';

export const WrapperIntro = styled(Wrapper)`
  position: relative;
  height: ${setVh(100)};
  width: 100vw;
  user-select: none;

  > .heading {
    position: absolute;
    height: ${({ theme: { locale } }) =>
      locale === 'en' ? '200rem' : '200rem'};
    width: auto;
    top: 0;
    margin-top: ${({ theme: { locale } }) =>
      locale === 'es' ? '260rem' : '200rem'};
    ${({ theme: { locale } }) => setTypography('heading3', locale)}
    font-size: 70rem;
  }

  > .can {
    position: absolute;
    height: 80%;
    width: auto;
    top: 5%;
  }

  > .continueWrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 110%;
    bottom: 5%;

    > span {
      height: 180rem;
      width: 180rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${colors.white};
      margin-left: 30rem;

      > svg {
        height: 55rem;
        ${({ theme }) => `color: ${theme.global.colors.font};`}
      }
    }
  }

  > .infoModal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: ${layout.zIndex.modals};

    > .dimBg {
      position: absolute;
      background-color: ${colors.black};
      opacity: 0.5;
      width: 100%;
      height: 100%;
      top: 0;
    }

    > .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 90%;
      height: auto;
      max-height: 90%;
      background-color: ${colors.white};
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
      border-radius: 5px;

      > p {
        text-align: center;
        margin-bottom: 50rem;
        ${({ theme: { locale } }) => setTypography('body1', locale)}
        font-size: ${({ theme: { locale } }) => locale === 'pk' && '45rem'};
        ${({ theme }) => `color: ${theme.global.colors.font};`}
        overflow-y: scroll;
        padding: 100rem 10% 50rem;

        > br {
          display: block;
          content: '';
          margin-top: 50rem;
        }

        > b {
          ${({ theme: { locale } }) => setTypography('body1Light', locale)}
          font-size: ${({ theme: { locale } }) => locale === 'pk' && '45rem'};
        }
      }

      > button {
        margin-bottom: 80rem;
      }
    }
  }
`;
