import React from 'react';

import * as Styled from './Liquid.styles';

export interface LiquidProps {
  number: number;
}

const defaultProps: Partial<LiquidProps> = {};

const Liquid: React.FC<LiquidProps> = ({ number }) => {
  return (
    <Styled.Wrapper
      $waveAnimNo={number % 10 ? (number + 5) / 10 - 1 : number / 10 - 1}
    >
      <div className="outer">
        <div className="can">
          <div className="liquid">
            <div className="bg" />
          </div>
        </div>
        <p>
          <span>{number}</span>
          <span>%</span>
        </p>
      </div>
    </Styled.Wrapper>
  );
};

Liquid.defaultProps = defaultProps;

export default React.memo(Liquid);
