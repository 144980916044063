import { motion } from 'framer-motion';
import styled from 'styled-components';

import { colors, layout } from 'utils/styles/theme';

export const Wrapper = styled(motion.div)`
  position: fixed;
  left: 60rem;
  top: 60rem;
  height: 130rem;
  width: 130rem;
  z-index: ${layout.zIndex.languageSelector};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};

  > svg {
    height: 55rem;
    ${({ theme }) => `color: ${theme.global.colors.font};`}
  }
`;
