import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import logo from 'assets/img/mirindaLogo.png';
import Button from 'components/Button/Button';
import Heading from 'components/Heading/Heading';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Modal from 'components/Modal/Modal';
import TermsModal from 'components/TermsModal/TermsModal';
import { authInstagram } from 'services/api';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as IconChecked } from 'svgs/checked.svg';
// import { ReactComponent as IconIG } from 'svgs/IG.svg';
import { ReactComponent as IconUnchecked } from 'svgs/unchecked.svg';
import GTM from 'u9/utils/gtm';
import {
  fadeMotionProps,
  loginMotionProps,
  pageMotionProps,
} from 'utils/styles/animations';

import * as Styled from '././styles/p4-SourceSelection.styles';

export interface SourceSelectionProps {
  copy: GlobalCopy['sourceSelection'];
  copyModals: GlobalCopy['modals'];
  locale: any;
}

const defaultProps: Partial<SourceSelectionProps> = {};

const SourceSelection: React.FC<SourceSelectionProps> = ({
  copy,
  copyModals,
  locale,
}) => {
  const { setImageSource, setIsUserSelected, setIsTermsAndConditions } =
    useGlobalStore();
  const [showIgLoader, setShowIgLoader] = useState(false);
  const [permissionsGranted, setPermissionsGranted] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const { theme } = useThemeStore();

  useEffect(() => GTM.trackPage('/', 'Source Selection Page'), []);

  const handleIGlogin = () => {
    GTM.trackEvent('Connect to your flavour', 'Click', 'Instagram Login');
    if (permissionsGranted) {
      setShowIgLoader(true);
      setImageSource('IG');
      setIsUserSelected(false);
      authInstagram(locale, setShowLoginError);
    } else {
      setShowLoginPrompt(true);
    }
  };

  const handleLocalGallery = () => {
    GTM.trackEvent('Connect to your flavour', 'Click', 'Local Gallery ');
    permissionsGranted ? setImageSource('LOCAL') : setShowLoginPrompt(true);
  };

  return (
    <>
      <Styled.WrapperSourceSelection>
        <AnimatePresence>
          <motion.div
            key="topWrapper"
            className="topWrapper"
            {...pageMotionProps}
          >
            <img src={logo} alt="" />
            <Heading
              title={copy.title}
              imgUrl={theme.headings?.sourceSelection}
              {...pageMotionProps}
            />
          </motion.div>
          <motion.section
            key="bottomWrapper"
            className="bottomWrapper"
            {...loginMotionProps}
          >
            <p dangerouslySetInnerHTML={{ __html: copy.description }} />
            {/* <Button
              label={copy.cta1}
              imgUrl={theme.ctas?.sourceSelection}
              onClick={handleIGlogin}
              showLabel={!showIgLoader}
            >
              { {showIgLoader ? (
                <Styled.DotsLoader>
                  <span></span>
                  <span></span>
                  <span></span>
                </Styled.DotsLoader>
              ) : (
                <IconIG />
              )}    </Button> */}

            <Styled.HiddenInput onClick={handleLocalGallery}>
              <ImageUpload permissionsGranted={permissionsGranted} />
              <Button
                label={copy.cta1}
                imgUrl={theme.ctas?.sourceSelection}
                onClick={handleIGlogin}
                showLabel={!showIgLoader}
              />
            </Styled.HiddenInput>

            {/* <Styled.HiddenInput onClick={handleLocalGallery}>
              <ImageUpload permissionsGranted={permissionsGranted} />

              <button dangerouslySetInnerHTML={{ __html: copy.cta2 }} />
            </Styled.HiddenInput> */}
            <div>
              <Styled.FakedCheckbox
                onClick={() => {
                  GTM.trackEvent(
                    'Connect to your flavour',
                    'Click',
                    'T&Cs Checkbox'
                  );

                  setPermissionsGranted(!permissionsGranted);
                }}
              >
                {permissionsGranted ? <IconChecked /> : <IconUnchecked />}
              </Styled.FakedCheckbox>
              <button>
                {copy.terms}
                <Styled.TermsButton
                  onClick={() => {
                    setShowTermsModal(true);
                    setIsTermsAndConditions(true);
                  }}
                >
                  {copy.termsCta}.
                </Styled.TermsButton>
              </button>
            </div>
          </motion.section>
        </AnimatePresence>
        <AnimatePresence exitBeforeEnter={true}>
          {showLoginPrompt && (
            <Modal
              src={{ label1: theme.ctas?.modalLetsGo }}
              copy={{
                body: copyModals.loginPrompt.body,
                label1: copyModals.loginPrompt.label1,
              }}
              onClick1={() => setShowLoginPrompt(false)}
              props={fadeMotionProps}
            />
          )}
          {showLoginError && (
            <Modal
              src={{
                title: theme.headings?.modalOops,
                label1: theme.ctas?.introClose,
              }}
              copy={{
                title: copyModals.oops.title,
                body: copyModals.oops.body,
                label1: copyModals.oops.label1,
              }}
              onClick1={() => setShowLoginError(false)}
              props={fadeMotionProps}
            />
          )}
        </AnimatePresence>
      </Styled.WrapperSourceSelection>
      <AnimatePresence>
        {showTermsModal && (
          <TermsModal
            onClose={() => {
              setShowTermsModal(false);
              setIsTermsAndConditions(false);
            }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

SourceSelection.defaultProps = defaultProps;

export default SourceSelection;
