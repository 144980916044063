import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

// import BackButton from 'components/BackButton/BackButton';
import Button from 'components/Button/Button';
import Heading from 'components/Heading/Heading';
import Modal from 'components/Modal/Modal';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { API, proceedToProcessingPage } from 'services/api';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as IconRestart } from 'svgs/restart.svg';
import GTM from 'u9/utils/gtm';
import { MAX_REFRESH_CAN_COUNT } from 'utils/constants';
import { SPA_ROUTES } from 'utils/routes';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './styles/p8-Artwork.styles';

import { AiError } from 'constants/enums';

export interface ArtworkProps {
  copy: GlobalCopy['artwork'];
  copyModals: GlobalCopy['modals'];
}

// const testImage =
//   'https://storage.googleapis.com/u9ltd-project-3278-mirind-c3bd-development-be-uploads/user_uploads/e962579fbba84ab1837717e8e99d3710_1679762012946404/72456cf763594705b86c087673a97dd2_1679762034416965.jpeg';

const defaultProps: Partial<ArtworkProps> = {};

const Artwork: React.FC<ArtworkProps> = ({ copy, copyModals }) => {
  const {
    setCurrentPage,
    imageUrl,
    imageSource,
    setImageUrl,
    lastSelectedFiles,
    lastSelectedUrls,
    playProcessingExitAnim,
    playProcessingPreEnterAnim,
    setLastSelectedUrls,
    gifSavedModal,
    gifWaitModal,
    showGifSavedModal,
    setImageUrls,
    imageUrls,
    refreshCanCount,
    setShareDisabled,
    shareDisabled,
    setRefreshCanCount,
    setAiError,
  } = useGlobalStore();
  const { theme } = useThemeStore();
  // const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [maxRefreshCanModal, setMaxRefreshCanModal] = useState<boolean>(false);

  // const [maxRefreshCanAchieved, setMaxRefreshCanAchieved] = useState<boolean>(
  //   refreshCanCount === MAX_REFRESH_CAN_COUNT
  // );

  useEffect(() => {
    playProcessingExitAnim(false);

    setImageUrls([...imageUrls, imageUrl]);
    setShareDisabled(false);
    // WebGLContainer.eventManager?.trigger('update_can_artwork', testImage); // DEBUG
    WebGLContainer.eventManager?.trigger('update_can_artwork', imageUrl);
    setAiError(AiError.none);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  const handleRestart = async () => {
    GTM.trackEvent('Your AI flavour', 'Click', 'Regenerate Can');
    if (refreshCanCount === MAX_REFRESH_CAN_COUNT) {
      setMaxRefreshCanModal(true);
      return;
    }
    if (imageUrls[refreshCanCount + 1]) {
      setImageUrl(imageUrls[refreshCanCount + 1]);
      setRefreshCanCount(refreshCanCount + 1);
    } else {
      setShareDisabled(true);
      WebGLContainer.eventManager?.trigger('hide_can_final');

      setRefreshCanCount(refreshCanCount + 1);

      setTimeout(() => {
        if (imageSource === 'IG') {
          const generateFromLinks = API.instance.generateFromLinks(
            lastSelectedUrls,
            setAiError,
            setCurrentPage,
            setImageUrl,
            playProcessingExitAnim,
            setLastSelectedUrls
          );
          console.log('await', generateFromLinks);
        } else {
          const generateFromImages = API.instance.generateFromImages(
            lastSelectedFiles,
            setAiError,
            setCurrentPage,
            setImageUrl,
            playProcessingExitAnim
          );
          console.log('await', generateFromImages);
        }

        proceedToProcessingPage(playProcessingPreEnterAnim, setCurrentPage);
      }, 300);
    }
  };

  // const handleBackBtn = useCallback(() => {
  //   if (refreshCanCount > 0) {
  //     setImageUrl(imageUrls[refreshCanCount - 1]);
  //     setRefreshCanCount(refreshCanCount - 1);
  //   }
  // }, [imageUrls, refreshCanCount, setImageUrl, setRefreshCanCount]);

  // useEffect(() => {
  //   // if (refreshCanCount >= 1) {
  //   //   setShowBackButton(true);
  //   // } else {
  //   //   setShowBackButton(false);
  //   // }
  //   if (refreshCanCount === MAX_REFRESH_CAN_COUNT) {
  //     setMaxRefreshCanAchieved(true);
  //   }
  // }, [refreshCanCount]);

  return (
    <motion.div {...fadeMotionProps}>
      {/* {showBackButton && (
        <BackButton isVisible={true} onClick={handleBackBtn} />
      )} */}

      <Styled.WrapperArtwork>
        <AnimatePresence exitBeforeEnter={true}>
          <Heading
            title={copy.title}
            imgUrl={theme.headings?.artwork}
            {...fadeMotionProps}
          />
        </AnimatePresence>

        <motion.div className="bottomWrapper">
          {console.log('shareDisabled', shareDisabled)}

          <button disabled={shareDisabled}>
            {refreshCanCount === 0 ||
            refreshCanCount >= MAX_REFRESH_CAN_COUNT ? (
              <Styled.WrapperButton
                isDisabled={refreshCanCount >= MAX_REFRESH_CAN_COUNT}
              >
                <IconRestart onClick={handleRestart} />
              </Styled.WrapperButton>
            ) : (
              <div onClick={handleRestart}>
                {MAX_REFRESH_CAN_COUNT - refreshCanCount}
              </div>
            )}
          </button>

          <Button
            label={copy.cta}
            imgUrl={theme.ctas?.artwork}
            whiteBg={true}
            onClick={() => {
              GTM.trackEvent('Your AI flavour', 'Click', 'Create More');
              WebGLContainer.eventManager?.trigger('hide_can_final');
              setTimeout(() => setCurrentPage(SPA_ROUTES.GALLERYPICKER), 300);
              setLastSelectedUrls([]);
              setImageUrls([]);
            }}
          />
        </motion.div>
        {gifWaitModal && (
          <Modal
            className="gifWait"
            copy={{
              body: copyModals.gifWait.body,
            }}
            props={fadeMotionProps}
          />
        )}
        {gifSavedModal && (
          <Modal
            src={{ label1: theme.ctas?.introClose, forceCopy: true }}
            copy={{
              body: copyModals.gifSaved.body,
              label1: copyModals.gifSaved.label1,
              forceCopy: true,
            }}
            onClick1={() => showGifSavedModal(false)}
            props={fadeMotionProps}
          />
        )}
        {maxRefreshCanModal && (
          <Modal
            src={{ label1: theme.ctas?.introClose }}
            copy={{
              body: copyModals.maxRefreshCan.body,
              label1: copyModals.maxRefreshCan.label1,
              forceCopy: true,
            }}
            onClick1={() => setMaxRefreshCanModal(false)}
            props={fadeMotionProps}
          />
        )}
      </Styled.WrapperArtwork>
    </motion.div>
  );
};

Artwork.defaultProps = defaultProps;

export default Artwork;
