import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import ImageUpload from 'components/ImageUpload/ImageUpload';
import { useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import GTM from 'u9/utils/gtm';
import { SPA_ROUTES } from 'utils/routes';
import { loginMotionProps } from 'utils/styles/animations';

import * as Styled from './styles/p5-FakeGallery.styles';

export interface FakeGalleryProps {
  copy: GlobalCopy['fakeGallery'];
  userCode: string;
}

const defaultProps: Partial<FakeGalleryProps> = {};

const FakeGallery: React.FC<FakeGalleryProps> = ({ copy, userCode }) => {
  const {
    setCurrentPage,
    imageSource,
    isUserSelected,
    showAtLeast3PostsError,
    allUrls,
  } = useGlobalStore();
  const [hasUserChosenImages, setHasUserChosenImages] = useState(false);
  const [imagesUrls, setImagesUrls] = useState([]);

  useEffect(() => GTM.trackPage('/', 'Gallery Page'), []);

  useEffect(() => {
    console.log('imageSource', imageSource);
    console.log('isUserSelected', isUserSelected);
    if (userCode && imageSource === 'IG' && isUserSelected) {
      setImagesUrls(allUrls);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSource, allUrls]);

  useEffect(() => {
    if (showAtLeast3PostsError) setCurrentPage(SPA_ROUTES.GALLERYPICKER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAtLeast3PostsError]);

  return (
    <Styled.WrapperFakeGallery>
      <AnimatePresence exitBeforeEnter={true}>
        <motion.section {...loginMotionProps}>
          <div className="btnWrapper">
            <button onClick={() => setCurrentPage(SPA_ROUTES.GALLERYPICKER)}>
              {copy.ctaCancel}
            </button>
            <button onClick={() => setHasUserChosenImages(true)}>
              {copy.ctaUpload}
            </button>
          </div>
          <ImageUpload
            isFakeGallery={true}
            copy={copy}
            hasUserChosenImages={hasUserChosenImages}
            setHasUserChosenImages={setHasUserChosenImages}
            imagesUrls={imagesUrls}
          />
        </motion.section>
      </AnimatePresence>
    </Styled.WrapperFakeGallery>
  );
};

FakeGallery.defaultProps = defaultProps;

export default FakeGallery;
