import styled from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const WrapperFakeGallery = styled(Wrapper)`
  position: relative;
  width: 100vw;
  height: ${setVh(100)};
  background-color: rgba(0, 0, 0, 0.5);
  user-select: none;

  > section {
    position: absolute;
    background-color: ${colors.white};
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
    height: ${setVh(97)};
    top: ${setVh(3)};
    left: 0;

    > .btnWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: ${setVh(7)};
      padding: 0 50rem;
      font-size: 50rem;
      font-family: Helvetica, Arial, sans-serif;
      color: ${colors.blueRibbon};

      > button:last-of-type {
        font-weight: bold;
      }
    }
  }
`;
