import styled from 'styled-components';

import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

interface WrapperProps {
  $isResizeEnabled: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100vw;
  height: ${setVh(91)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${setVh(3)};

  .uploadBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;
    display: none; // DEBUG

    > button,
    > input {
      width: 40%;
      height: 120rem;
    }

    > button {
      display: block;
      margin: 0 auto;
      border: 1px solid ${colors.black};
      ${({ theme: { locale } }) => setTypography('body2', locale)}
      padding: 16rem 40rem;
      pointer-events: none;
    }

    > input {
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      opacity: 0;
    }
  }

  .bottomWrapper {
    text-align: center;

    > p {
      font-family: Helvetica, Arial, sans-serif;
    }

    > p:first-of-type {
      font-size: 50rem;
      font-weight: bold;
      color: ${colors.blueRibbon};
    }

    > p:last-of-type {
      font-size: 35rem;
      margin: 10rem auto;
      opacity: 0.5;
    }
  }
`;
