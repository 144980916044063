import React from 'react';

import Button from 'components/Button/Button';
import Heading from 'components/Heading/Heading';

import * as Styled from './Modal.styles';

export interface ModalProps {
  onClick1?: (any) => void;
  onClick2?: (any) => void;
  copy: {
    title?: string;
    body: string;
    label1?: string;
    label2?: string;
    forceCopy?: boolean;
  };
  src?: {
    title?: string;
    label1?: string;
    label2?: string;
    forceCopy?: boolean;
  };
  twoLineTitle?: boolean;
  threeLineTitle?: boolean;
  className?: string;
  props?: any;
}

const Modal: React.FC<ModalProps> = ({
  onClick1,
  onClick2,
  copy,
  src,
  twoLineTitle = false,
  threeLineTitle = false,
  className,
  props,
}) => {
  return (
    <Styled.Wrapper
      $twoLineTitle={twoLineTitle}
      $threeLineTitle={threeLineTitle}
      className={className}
      {...props}
    >
      <div />
      <section>
        {copy.title && (
          <Heading
            title={copy.title}
            imgUrl={src ? src.title : null}
            forceCopy={src.forceCopy}
          />
        )}
        <p dangerouslySetInnerHTML={{ __html: copy.body }} />
        {copy.label1 && (
          <Button
            label={copy.label1}
            imgUrl={src ? src.label1 : null}
            onClick={onClick1}
            forceCopy={src.forceCopy}
          />
        )}
        {copy.label2 && (
          <Button
            label={copy.label2}
            imgUrl={src ? src.label2 : null}
            onClick={onClick2}
          />
        )}
      </section>
    </Styled.Wrapper>
  );
};

export default Modal;
