import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import AppBackground from 'components/AppBackground/AppBackground';
import BackButton from 'components/BackButton/BackButton';
// import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import Modal from 'components/Modal/Modal';
import Modals from 'components/Modals/Modals';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { API } from 'services/api';
import { useGlobalStore } from 'store';
import { getCopy } from 'store/copy.data';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import useWindowSize from 'u9/hooks/useWindowSize';
import { parseUrlQuery } from 'u9/utils/url';
import { Pages, SPA_ROUTES, SPA_ROUTES_PREV } from 'utils/routes';
import { fadeMotionProps } from 'utils/styles/animations';

import Loader from './p1-Loader';
import Landing from './p2-Landing';
import Intro from './p3-Intro';
import SourceSelection from './p4-SourceSelection';
import FakeGallery from './p5-FakeGallery';
import GalleryPicker from './p6-GalleryPicker';
import Processing from './p7-Processing';
import Artwork from './p8-Artwork';

import * as Styled from './styles/index.styles';

export interface IndexProps {
  copy: GlobalCopy;
  locale: any;
  market: any;
  country?: any;
  country2?: any;
  countryheaders?: any;
}

const Index: React.FC<IndexProps> = ({
  copy,
  locale,
  country,
  country2,
  countryheaders,
}) => {
  const { currentPage, setCurrentPage, isTermsAndConditions } =
    useGlobalStore();

  const [isOnline, setIsOnline] = useState(true);

  const { theme } = useThemeStore();

  const [isBackButtonVisible, setBackButtonVisible] = useState(false);
  const [prevPage, setPrevPage] = useState(SPA_ROUTES.PROCESSING);
  const [userCode, setUserCode] = useState('');
  const [isMounted, setMounted] = useState(false);

  const windowSize = useWindowSize();
  const [isLandscape, setIsLandscape] = useState(false);

  const handleOnline = () => {
    setIsOnline(true);
  };
  const handleOffline = () => {
    setIsOnline(false);
  };

  useEffect(() => {
    setMounted(true);
    // Hack gestures to block pinch-zoom
    document.addEventListener('gesturestart', e => {
      e.preventDefault();
    });

    document.addEventListener('gesturechange', e => {
      e.preventDefault();
    });
    document.addEventListener('gestureend', e => {
      e.preventDefault();
    });

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('locale', locale);
    API.instance.setLocale(locale);
  }, [locale]);

  useEffect(() => {
    console.log('v 2023_11_17_13_55_GTM+2');
    console.log('countryheaders', countryheaders);
  }, [countryheaders]);
  useEffect(() => {
    console.log('country', country);
  }, [country]);

  useEffect(() => {
    console.log('country2', country2);
  }, [country2]);
  useEffect(() => {
    if (isOnline) {
    }
  }, [isOnline]);

  useEffect(() => {
    const pageData = parseUrlQuery();
    if (process.env.IS_DEBUG && pageData.page) {
      const page = SPA_ROUTES[pageData.page.toUpperCase()];
      if (page) setCurrentPage(page);
    }

    if (pageData.code) {
      setUserCode(pageData.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      currentPage !== SPA_ROUTES.LOADER &&
      currentPage !== SPA_ROUTES.LANDING
    ) {
      setBackButtonVisible(true);
      setPrevPage(SPA_ROUTES_PREV[currentPage]);
    } else {
      setBackButtonVisible(false);
    }

    if (currentPage === SPA_ROUTES.LANDING) {
      document.body.style.overflowY = 'scroll';
      const htmlEl = document.querySelector('html');
      htmlEl.style.overflowY = 'scroll';
    } else {
      const htmlEl = document.querySelector('html');
      htmlEl.style.overflowY = 'hidden';
      document.body.style.overflowY = 'hidden';
    }
  }, [currentPage]);

  const props = {
    userCode: userCode,
    locale: locale,
  };

  const Container = {
    [SPA_ROUTES.LOADER]: <Loader {...props} copyModals={copy['modals']} />,
    [SPA_ROUTES.LANDING]: (
      <Landing {...props} copy={copy[SPA_ROUTES.LANDING]} />
    ),
    [SPA_ROUTES.INTRO]: <Intro {...props} copy={copy[SPA_ROUTES.INTRO]} />,
    [SPA_ROUTES.SOURCESELECTION]: (
      <SourceSelection
        {...props}
        copyModals={copy['modals']}
        copy={copy[SPA_ROUTES.SOURCESELECTION]}
      />
    ),
    [SPA_ROUTES.FAKEGALLERY]: (
      <FakeGallery {...props} copy={copy[SPA_ROUTES.FAKEGALLERY]} />
    ),
    [SPA_ROUTES.GALLERYPICKER]: (
      <GalleryPicker {...props} copy={copy[SPA_ROUTES.GALLERYPICKER]} />
    ),
    [SPA_ROUTES.PROCESSING]: <Processing copyModals={copy['modals']} />,
    [SPA_ROUTES.ARTWORK]: (
      <Artwork
        {...props}
        copy={copy[SPA_ROUTES.ARTWORK]}
        copyModals={copy['modals']}
      />
    ),
  };

  const pagesToShowModals = [
    SPA_ROUTES.SOURCESELECTION,
    SPA_ROUTES.GALLERYPICKER,
    SPA_ROUTES.FAKEGALLERY,
    SPA_ROUTES.PROCESSING,
  ];

  const handlePrevPage = () => {
    if (currentPage === SPA_ROUTES.INTRO) {
      WebGLContainer.eventManager?.trigger('hide_can_final');
    }
    if (currentPage === SPA_ROUTES.GALLERYPICKER) {
      setCurrentPage(SPA_ROUTES.ARTWORK);
    } else {
      setCurrentPage(prevPage);
    }
  };

  useEffect(() => {
    setIsLandscape(windowSize.isLandscape);
  }, [windowSize]);

  return (
    <Styled.Section className={currentPage} $isLandscape={isLandscape}>
      {isMounted && (
        <>
          <AppBackground />

          {/* <script
            dangerouslySetInnerHTML={{
              __html: `(function (w, d, s, l, i) {
                    w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
                    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                    j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
              })(window, document, 'script', 'dataLayer', 'GTM-KHS4GDW');`,
            }}
          ></script> */}

          {currentPage !== SPA_ROUTES.ARTWORK &&
            currentPage !== SPA_ROUTES.PROCESSING &&
            currentPage !== SPA_ROUTES.FAKEGALLERY &&
            !isTermsAndConditions && (
              <>
                <BackButton
                  isVisible={isBackButtonVisible}
                  onClick={handlePrevPage}
                />
                {/* {currentPage === SPA_ROUTES.LANDING && (
                  <LanguageSelector locale={locale} />
                )} */}
              </>
            )}

          {Container[currentPage]}
          {pagesToShowModals.some(el => currentPage.includes(el)) && (
            <Modals copy={copy['modals']} />
          )}

          <AnimatePresence>
            {!isOnline && (
              <Modal
                src={{
                  forceCopy: true,
                  title: theme.headings?.modalOops,
                  label1: theme.ctas?.modalBackHome,
                }}
                copy={{
                  forceCopy: true,
                  title: copy['modals'].oops.title,
                  body: copy['modals'].oops.body,
                  label1: copy['modals'].oops.label1,
                }}
                onClick1={() => {
                  window.location.reload();
                }}
                props={fadeMotionProps}
              />
            )}
          </AnimatePresence>
          <div id="footerlist"></div>
        </>
      )}
    </Styled.Section>
  );
};

export default Index;

export const getServerSideProps: any = async ctx => {
  const { resolvedUrl, res, req } = ctx;

  const country = ctx.req?.app?.country || 'noData';
  const country2 = ctx.req?.app?.country2 || 'noData';

  const matches = resolvedUrl.match(/\/([^\\/]{0,2})/);

  // const countryheaders = {
  //   'cloudfront-viewer-country': 'PK',
  // };
  const countryheaders = ctx.req.headers;
  const localeMarket =
    countryheaders?.['cloudfront-viewer-country']?.toLocaleLowerCase();
  const market = matches?.[1] || 'en';
  const locale = market === 'pk' ? 'pk' : market === 'sa' ? 'sa' : 'en';
  // const locale = market === 'vn' ? 'vi' : market === 'mx' ? 'es' : 'en';
  const copyMain = getCopy(Pages.index, locale);

  if (req._parsedUrl.pathname === '/' && localeMarket === 'pk') {
    res.setHeader('location', '/pk');
    res.statusCode = 302;
    res.end();
    return { props: {} };
  }

  return {
    props: {
      copy: copyMain.global,
      locale,
      market,
      country,
      country2,
      countryheaders,
    },
  };
};
