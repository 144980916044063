import { AnimatePresence } from 'framer-motion';
import React, { useCallback } from 'react';

import Modal from 'components/Modal/Modal';
import { useGlobalStore } from 'store';
import useThemeStore from 'store/visuals';
import { SPA_ROUTES } from 'utils/routes';
import { fadeMotionProps } from 'utils/styles/animations';

export interface ModalsProps {
  copy: any;
}

const Modals: React.FC<ModalsProps> = ({ copy }) => {
  const {
    showAtLeast3ImagesError,
    setShowAtLeast3ImagesError,
    showAtLeast3PostsError,
    setShowAtLeast3PostsError,
    setCurrentPage,
  } = useGlobalStore();
  const { theme } = useThemeStore();

  const handleNeed3Posts = () => {
    setShowAtLeast3PostsError(false);
    setTimeout(() => setCurrentPage(SPA_ROUTES.GALLERYPICKER), 500);
  };

  const clearSelection3Images = useCallback(() => {
    setShowAtLeast3ImagesError(false);
  }, [setShowAtLeast3ImagesError]);

  return (
    <AnimatePresence exitBeforeEnter={true}>
      {showAtLeast3ImagesError && (
        <Modal
          src={{
            title: theme.headings?.modalSelect3,
            label1: theme.ctas?.modalTryAgain,
          }}
          copy={{
            title: copy.selectAtLeast3.title,
            body: copy.selectAtLeast3.body,
            label1: copy.selectAtLeast3.label1,
          }}
          onClick1={clearSelection3Images}
          twoLineTitle={true}
          props={fadeMotionProps}
        />
      )}
      {showAtLeast3PostsError && (
        <Modal
          src={{
            title: theme.headings?.modalMorePosts,
            label1: theme.ctas?.modalTryAgain,
          }}
          copy={{
            title: copy.atLeast3Posts.title,
            body: copy.atLeast3Posts.body,
            label1: copy.atLeast3Posts.label1,
          }}
          onClick1={handleNeed3Posts}
          threeLineTitle={true}
          props={fadeMotionProps}
        />
      )}
    </AnimatePresence>
  );
};

export default Modals;
