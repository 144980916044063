import styled from 'styled-components';

import { setVh } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  width: 100vw;
  height: ${setVh(80)};
  overflow-y: scroll;

  > div {
    display: flex;
    align-items: start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
`;
