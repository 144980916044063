import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/Button/Button';
import Heading from 'components/Heading/Heading';
import { sleep } from 'components/ThreeView/utils/time';
import { WebGLContainer } from 'components/ThreeView/utils/webglContainer';
import { useCopyStore, useGlobalStore } from 'store';
import { GlobalCopy } from 'store/copy.types';
import useThemeStore from 'store/visuals';
import { ReactComponent as IconEN } from 'svgs/info_en.svg';
import { ReactComponent as IconVI } from 'svgs/info_vi.svg';
import GTM from 'u9/utils/gtm';
import { SPA_ROUTES } from 'utils/routes';
import { fadeMotionProps, pageMotionProps } from 'utils/styles/animations';

import * as Styled from './styles/p3-Intro.styles';

export interface IntroProps {
  copy: GlobalCopy['intro'];
}

const defaultProps: Partial<IntroProps> = {};

const Intro: React.FC<IntroProps> = ({ copy }) => {
  const { setCurrentPage, playBackgroundUpAnim } = useGlobalStore();
  const { theme } = useThemeStore();
  const { userLang } = useCopyStore();
  const [headingSrc, setHeadingSrc] = useState(theme.headings?.intro1);
  const [headingCopy, setHeadingCopy] = useState(copy.title1);
  const [showContinueWrapper, setShowContinueWrapper] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const timeOutA = useRef(null);

  useEffect(() => {
    GTM.trackPage('/', 'Intro Page');
    introAnim();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (timeOutA.current) clearTimeout(timeOutA.current);
    setHeadingSrc(theme.headings?.intro1);
    setHeadingCopy(copy.title1);
    setShowContinueWrapper(false);
    introAnim();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLang]);

  const introAnim = () => {
    WebGLContainer.eventManager?.trigger('restore_initial');
    setTimeout(
      () => WebGLContainer.eventManager?.trigger('intro_can_rotate'),
      10
    );

    timeOutA.current = setTimeout(() => {
      setHeadingSrc(theme.headings?.intro2);
      setHeadingCopy(copy.title2);
      setShowContinueWrapper(true);
    }, 4000);
  };

  const handleContinue = async () => {
    WebGLContainer.eventManager?.trigger('intro_can_bottom');
    setShowTitle(false);
    GTM.trackEvent('Make your own AI Can', 'Click', 'Continue');
    await sleep(1000);
    playBackgroundUpAnim(true);
    await sleep(2000);
    setCurrentPage(SPA_ROUTES.SOURCESELECTION);
    await sleep(500);
    playBackgroundUpAnim(false);
  };

  return (
    <Styled.WrapperIntro {...pageMotionProps}>
      <AnimatePresence exitBeforeEnter={true}>
        {showTitle && (
          <Heading
            title={headingCopy}
            imgUrl={headingSrc}
            {...pageMotionProps}
          />
        )}
      </AnimatePresence>

      <AnimatePresence exitBeforeEnter={true}>
        {showContinueWrapper && (
          <motion.div className="continueWrapper" {...pageMotionProps}>
            <Button
              label={copy.continueCta}
              imgUrl={theme.ctas?.introContinue}
              onClick={handleContinue}
              whiteBg
            />
            <span
              onClick={() => {
                setShowInfoModal(true);
                GTM.trackEvent('Make your own AI Can', 'Click', 'Info');
              }}
            >
              {theme.locale === 'vi' ? <IconVI /> : <IconEN />}
            </span>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showInfoModal && (
          <motion.div className="infoModal" {...fadeMotionProps}>
            <div className="dimBg" />
            <div className="wrapper">
              <p dangerouslySetInnerHTML={{ __html: copy.info }} />
              <Button
                label={copy.closeCta}
                imgUrl={theme.ctas?.introClose}
                onClick={() => {
                  setShowInfoModal(false);
                }}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Styled.WrapperIntro>
  );
};

Intro.defaultProps = defaultProps;

export default Intro;
