import { keyframes } from 'styled-components';

export const wave10 = () => keyframes`
    from {
      opacity: var(--o, 1);
      clip-path: polygon(100% 100%, 0% 100% , 0.00% 88.00%, 2.00% 87.97%, 4.00% 87.87%, 6.00% 87.72%, 8.00% 87.51%, 10.00% 87.24%, 12.00% 86.92%, 14.00% 86.55%, 16.00% 86.14%, 18.00% 85.70%, 20.00% 85.24%, 22.00% 84.75%, 24.00% 84.25%, 26.00% 83.75%, 28.00% 83.25%, 30.00% 82.76%, 32.00% 82.30%, 34.00% 81.86%, 36.00% 81.45%, 38.00% 81.08%, 40.00% 80.76%, 42.00% 80.49%, 44.00% 80.28%, 46.00% 80.13%, 48.00% 80.03%, 50.00% 80.00%, 52.00% 80.03%, 54.00% 80.13%, 56.00% 80.28%, 58.00% 80.49%, 60.00% 80.76%, 62.00% 81.08%, 64.00% 81.45%, 66.00% 81.86%, 68.00% 82.30%, 70.00% 82.76%, 72.00% 83.25%, 74.00% 83.75%, 76.00% 84.25%, 78.00% 84.75%, 80.00% 85.24%, 82.00% 85.70%, 84.00% 86.14%, 86.00% 86.55%, 88.00% 86.92%, 90.00% 87.24%, 92.00% 87.51%, 94.00% 87.72%, 96.00% 87.87%, 98.00% 87.97%, 100.00% 88.00%);
    }

    25% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 86.00%, 2.00% 85.55%, 4.00% 85.08%, 6.00% 84.58%, 8.00% 84.08%, 10.00% 83.58%, 12.00% 83.09%, 14.00% 82.61%, 16.00% 82.15%, 18.00% 81.72%, 20.00% 81.32%, 22.00% 80.97%, 24.00% 80.67%, 26.00% 80.42%, 28.00% 80.22%, 30.00% 80.09%, 32.00% 80.01%, 34.00% 80.00%, 36.00% 80.06%, 38.00% 80.17%, 40.00% 80.35%, 42.00% 80.58%, 44.00% 80.87%, 46.00% 81.20%, 48.00% 81.58%, 50.00% 82.00%, 52.00% 82.45%, 54.00% 82.92%, 56.00% 83.42%, 58.00% 83.92%, 60.00% 84.42%, 62.00% 84.91%, 64.00% 85.39%, 66.00% 85.85%, 68.00% 86.28%, 70.00% 86.68%, 72.00% 87.03%, 74.00% 87.33%, 76.00% 87.58%, 78.00% 87.78%, 80.00% 87.91%, 82.00% 87.99%, 84.00% 88.00%, 86.00% 87.94%, 88.00% 87.83%, 90.00% 87.65%, 92.00% 87.42%, 94.00% 87.13%, 96.00% 86.80%, 98.00% 86.42%, 100.00% 86.00%);
    }

    50% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 80.00%, 2.00% 80.03%, 4.00% 80.13%, 6.00% 80.28%, 8.00% 80.49%, 10.00% 80.76%, 12.00% 81.08%, 14.00% 81.45%, 16.00% 81.86%, 18.00% 82.30%, 20.00% 82.76%, 22.00% 83.25%, 24.00% 83.75%, 26.00% 84.25%, 28.00% 84.75%, 30.00% 85.24%, 32.00% 85.70%, 34.00% 86.14%, 36.00% 86.55%, 38.00% 86.92%, 40.00% 87.24%, 42.00% 87.51%, 44.00% 87.72%, 46.00% 87.87%, 48.00% 87.97%, 50.00% 88.00%, 52.00% 87.97%, 54.00% 87.87%, 56.00% 87.72%, 58.00% 87.51%, 60.00% 87.24%, 62.00% 86.92%, 64.00% 86.55%, 66.00% 86.14%, 68.00% 85.70%, 70.00% 85.24%, 72.00% 84.75%, 74.00% 84.25%, 76.00% 83.75%, 78.00% 83.25%, 80.00% 82.76%, 82.00% 82.30%, 84.00% 81.86%, 86.00% 81.45%, 88.00% 81.08%, 90.00% 80.76%, 92.00% 80.49%, 94.00% 80.28%, 96.00% 80.13%, 98.00% 80.03%, 100.00% 80.00%);
    }

    to {
      opacity: var(--o, 1);
      clip-path: polygon(100% 100%, 0% 100% , 0.00% 88.00%, 2.00% 87.97%, 4.00% 87.87%, 6.00% 87.72%, 8.00% 87.51%, 10.00% 87.24%, 12.00% 86.92%, 14.00% 86.55%, 16.00% 86.14%, 18.00% 85.70%, 20.00% 85.24%, 22.00% 84.75%, 24.00% 84.25%, 26.00% 83.75%, 28.00% 83.25%, 30.00% 82.76%, 32.00% 82.30%, 34.00% 81.86%, 36.00% 81.45%, 38.00% 81.08%, 40.00% 80.76%, 42.00% 80.49%, 44.00% 80.28%, 46.00% 80.13%, 48.00% 80.03%, 50.00% 80.00%, 52.00% 80.03%, 54.00% 80.13%, 56.00% 80.28%, 58.00% 80.49%, 60.00% 80.76%, 62.00% 81.08%, 64.00% 81.45%, 66.00% 81.86%, 68.00% 82.30%, 70.00% 82.76%, 72.00% 83.25%, 74.00% 83.75%, 76.00% 84.25%, 78.00% 84.75%, 80.00% 85.24%, 82.00% 85.70%, 84.00% 86.14%, 86.00% 86.55%, 88.00% 86.92%, 90.00% 87.24%, 92.00% 87.51%, 94.00% 87.72%, 96.00% 87.87%, 98.00% 87.97%, 100.00% 88.00%);
    }
`;

export const wave20 = () => keyframes`
    from {
      opacity: var(--o, 1);
      clip-path: polygon(100% 100%, 0% 100% , 0.00% 80.00%, 2.00% 79.99%, 4.00% 79.97%, 6.00% 79.93%, 8.00% 79.87%, 10.00% 79.80%, 12.00% 79.72%, 14.00% 79.62%, 16.00% 79.51%, 18.00% 79.38%, 20.00% 79.24%, 22.00% 79.08%, 24.00% 78.92%, 26.00% 78.74%, 28.00% 78.55%, 30.00% 78.35%, 32.00% 78.14%, 34.00% 77.93%, 36.00% 77.70%, 38.00% 77.47%, 40.00% 77.24%, 42.00% 76.99%, 44.00% 76.75%, 46.00% 76.50%, 48.00% 76.25%, 50.00% 76.00%, 52.00% 75.75%, 54.00% 75.50%, 56.00% 75.25%, 58.00% 75.01%, 60.00% 74.76%, 62.00% 74.53%, 64.00% 74.30%, 66.00% 74.07%, 68.00% 73.86%, 70.00% 73.65%, 72.00% 73.45%, 74.00% 73.26%, 76.00% 73.08%, 78.00% 72.92%, 80.00% 72.76%, 82.00% 72.62%, 84.00% 72.49%, 86.00% 72.38%, 88.00% 72.28%, 90.00% 72.20%, 92.00% 72.13%, 94.00% 72.07%, 96.00% 72.03%, 98.00% 72.01%, 100.00% 72.00%);
    }

    25% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 78.00%, 2.00% 77.78%, 4.00% 77.55%, 6.00% 77.32%, 8.00% 77.08%, 10.00% 76.83%, 12.00% 76.58%, 14.00% 76.33%, 16.00% 76.08%, 18.00% 75.83%, 20.00% 75.58%, 22.00% 75.33%, 24.00% 75.09%, 26.00% 74.84%, 28.00% 74.61%, 30.00% 74.37%, 32.00% 74.15%, 34.00% 73.93%, 36.00% 73.72%, 38.00% 73.52%, 40.00% 73.32%, 42.00% 73.14%, 44.00% 72.97%, 46.00% 72.81%, 48.00% 72.67%, 50.00% 72.54%, 52.00% 72.42%, 54.00% 72.31%, 56.00% 72.22%, 58.00% 72.15%, 60.00% 72.09%, 62.00% 72.04%, 64.00% 72.01%, 66.00% 72.00%, 68.00% 72.00%, 70.00% 72.02%, 72.00% 72.06%, 74.00% 72.11%, 76.00% 72.17%, 78.00% 72.25%, 80.00% 72.35%, 82.00% 72.46%, 84.00% 72.58%, 86.00% 72.72%, 88.00% 72.87%, 90.00% 73.03%, 92.00% 73.20%, 94.00% 73.39%, 96.00% 73.58%, 98.00% 73.79%, 100.00% 74.00%);
    }

    50% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 76.00%, 2.00% 76.25%, 4.00% 76.50%, 6.00% 76.75%, 8.00% 76.99%, 10.00% 77.24%, 12.00% 77.47%, 14.00% 77.70%, 16.00% 77.93%, 18.00% 78.14%, 20.00% 78.35%, 22.00% 78.55%, 24.00% 78.74%, 26.00% 78.92%, 28.00% 79.08%, 30.00% 79.24%, 32.00% 79.38%, 34.00% 79.51%, 36.00% 79.62%, 38.00% 79.72%, 40.00% 79.80%, 42.00% 79.87%, 44.00% 79.93%, 46.00% 79.97%, 48.00% 79.99%, 50.00% 80.00%, 52.00% 79.99%, 54.00% 79.97%, 56.00% 79.93%, 58.00% 79.87%, 60.00% 79.80%, 62.00% 79.72%, 64.00% 79.62%, 66.00% 79.51%, 68.00% 79.38%, 70.00% 79.24%, 72.00% 79.08%, 74.00% 78.92%, 76.00% 78.74%, 78.00% 78.55%, 80.00% 78.35%, 82.00% 78.14%, 84.00% 77.93%, 86.00% 77.70%, 88.00% 77.47%, 90.00% 77.24%, 92.00% 76.99%, 94.00% 76.75%, 96.00% 76.50%, 98.00% 76.25%, 100.00% 76.00%);
    }

    to {
      opacity: var(--o, 1);
          clip-path: polygon(100% 100%, 0% 100% , 0.00% 80.00%, 2.00% 79.99%, 4.00% 79.97%, 6.00% 79.93%, 8.00% 79.87%, 10.00% 79.80%, 12.00% 79.72%, 14.00% 79.62%, 16.00% 79.51%, 18.00% 79.38%, 20.00% 79.24%, 22.00% 79.08%, 24.00% 78.92%, 26.00% 78.74%, 28.00% 78.55%, 30.00% 78.35%, 32.00% 78.14%, 34.00% 77.93%, 36.00% 77.70%, 38.00% 77.47%, 40.00% 77.24%, 42.00% 76.99%, 44.00% 76.75%, 46.00% 76.50%, 48.00% 76.25%, 50.00% 76.00%, 52.00% 75.75%, 54.00% 75.50%, 56.00% 75.25%, 58.00% 75.01%, 60.00% 74.76%, 62.00% 74.53%, 64.00% 74.30%, 66.00% 74.07%, 68.00% 73.86%, 70.00% 73.65%, 72.00% 73.45%, 74.00% 73.26%, 76.00% 73.08%, 78.00% 72.92%, 80.00% 72.76%, 82.00% 72.62%, 84.00% 72.49%, 86.00% 72.38%, 88.00% 72.28%, 90.00% 72.20%, 92.00% 72.13%, 94.00% 72.07%, 96.00% 72.03%, 98.00% 72.01%, 100.00% 72.00%);
    }
`;

export const wave30 = () => keyframes`
    from {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 74.00%, 2.00% 73.95%, 4.00% 73.81%, 6.00% 73.58%, 8.00% 73.26%, 10.00% 72.85%, 12.00% 72.37%, 14.00% 71.82%, 16.00% 71.21%, 18.00% 70.55%, 20.00% 69.85%, 22.00% 69.12%, 24.00% 68.38%, 26.00% 67.62%, 28.00% 66.88%, 30.00% 66.15%, 32.00% 65.45%, 34.00% 64.79%, 36.00% 64.18%, 38.00% 63.63%, 40.00% 63.15%, 42.00% 62.74%, 44.00% 62.42%, 46.00% 62.19%, 48.00% 62.05%, 50.00% 62.00%, 52.00% 62.05%, 54.00% 62.19%, 56.00% 62.42%, 58.00% 62.74%, 60.00% 63.15%, 62.00% 63.63%, 64.00% 64.18%, 66.00% 64.79%, 68.00% 65.45%, 70.00% 66.15%, 72.00% 66.88%, 74.00% 67.62%, 76.00% 68.38%, 78.00% 69.12%, 80.00% 69.85%, 82.00% 70.55%, 84.00% 71.21%, 86.00% 71.82%, 88.00% 72.37%, 90.00% 72.85%, 92.00% 73.26%, 94.00% 73.58%, 96.00% 73.81%, 98.00% 73.95%, 100.00% 74.00%);
    }

    25% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 71.00%, 2.00% 70.33%, 4.00% 69.61%, 6.00% 68.88%, 8.00% 68.13%, 10.00% 67.37%, 12.00% 66.63%, 14.00% 65.91%, 16.00% 65.22%, 18.00% 64.58%, 20.00% 63.99%, 22.00% 63.46%, 24.00% 63.00%, 26.00% 62.63%, 28.00% 62.33%, 30.00% 62.13%, 32.00% 62.02%, 34.00% 62.01%, 36.00% 62.08%, 38.00% 62.26%, 40.00% 62.52%, 42.00% 62.87%, 44.00% 63.30%, 46.00% 63.80%, 48.00% 64.37%, 50.00% 65.00%, 52.00% 65.67%, 54.00% 66.39%, 56.00% 67.12%, 58.00% 67.87%, 60.00% 68.63%, 62.00% 69.37%, 64.00% 70.09%, 66.00% 70.78%, 68.00% 71.42%, 70.00% 72.01%, 72.00% 72.54%, 74.00% 73.00%, 76.00% 73.37%, 78.00% 73.67%, 80.00% 73.87%, 82.00% 73.98%, 84.00% 73.99%, 86.00% 73.92%, 88.00% 73.74%, 90.00% 73.48%, 92.00% 73.13%, 94.00% 72.70%, 96.00% 72.20%, 98.00% 71.63%, 100.00% 71.00%);
    }

    50% {
        clip-path: polygon(100% 100%, 0% 100% , 0.00% 62.00%, 2.00% 62.05%, 4.00% 62.19%, 6.00% 62.42%, 8.00% 62.74%, 10.00% 63.15%, 12.00% 63.63%, 14.00% 64.18%, 16.00% 64.79%, 18.00% 65.45%, 20.00% 66.15%, 22.00% 66.88%, 24.00% 67.62%, 26.00% 68.38%, 28.00% 69.12%, 30.00% 69.85%, 32.00% 70.55%, 34.00% 71.21%, 36.00% 71.82%, 38.00% 72.37%, 40.00% 72.85%, 42.00% 73.26%, 44.00% 73.58%, 46.00% 73.81%, 48.00% 73.95%, 50.00% 74.00%, 52.00% 73.95%, 54.00% 73.81%, 56.00% 73.58%, 58.00% 73.26%, 60.00% 72.85%, 62.00% 72.37%, 64.00% 71.82%, 66.00% 71.21%, 68.00% 70.55%, 70.00% 69.85%, 72.00% 69.12%, 74.00% 68.38%, 76.00% 67.62%, 78.00% 66.88%, 80.00% 66.15%, 82.00% 65.45%, 84.00% 64.79%, 86.00% 64.18%, 88.00% 63.63%, 90.00% 63.15%, 92.00% 62.74%, 94.00% 62.42%, 96.00% 62.19%, 98.00% 62.05%, 100.00% 62.00%);
    }

    to {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 74.00%, 2.00% 73.95%, 4.00% 73.81%, 6.00% 73.58%, 8.00% 73.26%, 10.00% 72.85%, 12.00% 72.37%, 14.00% 71.82%, 16.00% 71.21%, 18.00% 70.55%, 20.00% 69.85%, 22.00% 69.12%, 24.00% 68.38%, 26.00% 67.62%, 28.00% 66.88%, 30.00% 66.15%, 32.00% 65.45%, 34.00% 64.79%, 36.00% 64.18%, 38.00% 63.63%, 40.00% 63.15%, 42.00% 62.74%, 44.00% 62.42%, 46.00% 62.19%, 48.00% 62.05%, 50.00% 62.00%, 52.00% 62.05%, 54.00% 62.19%, 56.00% 62.42%, 58.00% 62.74%, 60.00% 63.15%, 62.00% 63.63%, 64.00% 64.18%, 66.00% 64.79%, 68.00% 65.45%, 70.00% 66.15%, 72.00% 66.88%, 74.00% 67.62%, 76.00% 68.38%, 78.00% 69.12%, 80.00% 69.85%, 82.00% 70.55%, 84.00% 71.21%, 86.00% 71.82%, 88.00% 72.37%, 90.00% 72.85%, 92.00% 73.26%, 94.00% 73.58%, 96.00% 73.81%, 98.00% 73.95%, 100.00% 74.00%);
    }
`;

export const wave40 = () => keyframes`
    from {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 68.00%, 2.00% 67.98%, 4.00% 67.94%, 6.00% 67.86%, 8.00% 67.75%, 10.00% 67.61%, 12.00% 67.44%, 14.00% 67.24%, 16.00% 67.01%, 18.00% 66.75%, 20.00% 66.47%, 22.00% 66.16%, 24.00% 65.83%, 26.00% 65.48%, 28.00% 65.10%, 30.00% 64.70%, 32.00% 64.29%, 34.00% 63.85%, 36.00% 63.41%, 38.00% 62.94%, 40.00% 62.47%, 42.00% 61.99%, 44.00% 61.50%, 46.00% 61.00%, 48.00% 60.50%, 50.00% 60.00%, 52.00% 59.50%, 54.00% 59.00%, 56.00% 58.50%, 58.00% 58.01%, 60.00% 57.53%, 62.00% 57.06%, 64.00% 56.59%, 66.00% 56.15%, 68.00% 55.71%, 70.00% 55.30%, 72.00% 54.90%, 74.00% 54.52%, 76.00% 54.17%, 78.00% 53.84%, 80.00% 53.53%, 82.00% 53.25%, 84.00% 52.99%, 86.00% 52.76%, 88.00% 52.56%, 90.00% 52.39%, 92.00% 52.25%, 94.00% 52.14%, 96.00% 52.06%, 98.00% 52.02%, 100.00% 52.00%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 64.00%, 2.00% 63.56%, 4.00% 63.10%, 6.00% 62.63%, 8.00% 62.15%, 10.00% 61.66%, 12.00% 61.17%, 14.00% 60.67%, 16.00% 60.17%, 18.00% 59.66%, 20.00% 59.16%, 22.00% 58.67%, 24.00% 58.17%, 26.00% 57.69%, 28.00% 57.21%, 30.00% 56.75%, 32.00% 56.29%, 34.00% 55.86%, 36.00% 55.43%, 38.00% 55.03%, 40.00% 54.65%, 42.00% 54.28%, 44.00% 53.94%, 46.00% 53.63%, 48.00% 53.34%, 50.00% 53.07%, 52.00% 52.83%, 54.00% 52.63%, 56.00% 52.44%, 58.00% 52.29%, 60.00% 52.17%, 62.00% 52.09%, 64.00% 52.03%, 66.00% 52.00%, 68.00% 52.01%, 70.00% 52.04%, 72.00% 52.11%, 74.00% 52.21%, 76.00% 52.34%, 78.00% 52.50%, 80.00% 52.69%, 82.00% 52.91%, 84.00% 53.16%, 86.00% 53.43%, 88.00% 53.73%, 90.00% 54.05%, 92.00% 54.40%, 94.00% 54.77%, 96.00% 55.16%, 98.00% 55.57%, 100.00% 56.00%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 60.00%, 2.00% 60.50%, 4.00% 61.00%, 6.00% 61.50%, 8.00% 61.99%, 10.00% 62.47%, 12.00% 62.94%, 14.00% 63.41%, 16.00% 63.85%, 18.00% 64.29%, 20.00% 64.70%, 22.00% 65.10%, 24.00% 65.48%, 26.00% 65.83%, 28.00% 66.16%, 30.00% 66.47%, 32.00% 66.75%, 34.00% 67.01%, 36.00% 67.24%, 38.00% 67.44%, 40.00% 67.61%, 42.00% 67.75%, 44.00% 67.86%, 46.00% 67.94%, 48.00% 67.98%, 50.00% 68.00%, 52.00% 67.98%, 54.00% 67.94%, 56.00% 67.86%, 58.00% 67.75%, 60.00% 67.61%, 62.00% 67.44%, 64.00% 67.24%, 66.00% 67.01%, 68.00% 66.75%, 70.00% 66.47%, 72.00% 66.16%, 74.00% 65.83%, 76.00% 65.48%, 78.00% 65.10%, 80.00% 64.70%, 82.00% 64.29%, 84.00% 63.85%, 86.00% 63.41%, 88.00% 62.94%, 90.00% 62.47%, 92.00% 61.99%, 94.00% 61.50%, 96.00% 61.00%, 98.00% 60.50%, 100.00% 60.00%);
    }

    to {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 68.00%, 2.00% 67.98%, 4.00% 67.94%, 6.00% 67.86%, 8.00% 67.75%, 10.00% 67.61%, 12.00% 67.44%, 14.00% 67.24%, 16.00% 67.01%, 18.00% 66.75%, 20.00% 66.47%, 22.00% 66.16%, 24.00% 65.83%, 26.00% 65.48%, 28.00% 65.10%, 30.00% 64.70%, 32.00% 64.29%, 34.00% 63.85%, 36.00% 63.41%, 38.00% 62.94%, 40.00% 62.47%, 42.00% 61.99%, 44.00% 61.50%, 46.00% 61.00%, 48.00% 60.50%, 50.00% 60.00%, 52.00% 59.50%, 54.00% 59.00%, 56.00% 58.50%, 58.00% 58.01%, 60.00% 57.53%, 62.00% 57.06%, 64.00% 56.59%, 66.00% 56.15%, 68.00% 55.71%, 70.00% 55.30%, 72.00% 54.90%, 74.00% 54.52%, 76.00% 54.17%, 78.00% 53.84%, 80.00% 53.53%, 82.00% 53.25%, 84.00% 52.99%, 86.00% 52.76%, 88.00% 52.56%, 90.00% 52.39%, 92.00% 52.25%, 94.00% 52.14%, 96.00% 52.06%, 98.00% 52.02%, 100.00% 52.00%);
    }
`;

export const wave50 = () => keyframes`
    from {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 64.00%, 2.00% 63.98%, 4.00% 63.91%, 6.00% 63.79%, 8.00% 63.62%, 10.00% 63.41%, 12.00% 63.16%, 14.00% 62.86%, 16.00% 62.52%, 18.00% 62.13%, 20.00% 61.71%, 22.00% 61.25%, 24.00% 60.75%, 26.00% 60.21%, 28.00% 59.65%, 30.00% 59.05%, 32.00% 58.43%, 34.00% 57.78%, 36.00% 57.11%, 38.00% 56.42%, 40.00% 55.71%, 42.00% 54.98%, 44.00% 54.25%, 46.00% 53.50%, 48.00% 52.75%, 50.00% 52.00%, 52.00% 51.25%, 54.00% 50.50%, 56.00% 49.75%, 58.00% 49.02%, 60.00% 48.29%, 62.00% 47.58%, 64.00% 46.89%, 66.00% 46.22%, 68.00% 45.57%, 70.00% 44.95%, 72.00% 44.35%, 74.00% 43.79%, 76.00% 43.25%, 78.00% 42.75%, 80.00% 42.29%, 82.00% 41.87%, 84.00% 41.48%, 86.00% 41.14%, 88.00% 40.84%, 90.00% 40.59%, 92.00% 40.38%, 94.00% 40.21%, 96.00% 40.09%, 98.00% 40.02%, 100.00% 40.00%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 52.00%, 2.00% 51.25%, 4.00% 50.50%, 6.00% 49.75%, 8.00% 49.02%, 10.00% 48.29%, 12.00% 47.58%, 14.00% 46.89%, 16.00% 46.22%, 18.00% 45.57%, 20.00% 44.95%, 22.00% 44.35%, 24.00% 43.79%, 26.00% 43.25%, 28.00% 42.75%, 30.00% 42.29%, 32.00% 41.87%, 34.00% 41.48%, 36.00% 41.14%, 38.00% 40.84%, 40.00% 40.59%, 42.00% 40.38%, 44.00% 40.21%, 46.00% 40.09%, 48.00% 40.02%, 50.00% 40.00%, 52.00% 40.02%, 54.00% 40.09%, 56.00% 40.21%, 58.00% 40.38%, 60.00% 40.59%, 62.00% 40.84%, 64.00% 41.14%, 66.00% 41.48%, 68.00% 41.87%, 70.00% 42.29%, 72.00% 42.75%, 74.00% 43.25%, 76.00% 43.79%, 78.00% 44.35%, 80.00% 44.95%, 82.00% 45.57%, 84.00% 46.22%, 86.00% 46.89%, 88.00% 47.58%, 90.00% 48.29%, 92.00% 49.02%, 94.00% 49.75%, 96.00% 50.50%, 98.00% 51.25%, 100.00% 52.00%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 40.00%, 2.00% 40.02%, 4.00% 40.09%, 6.00% 40.21%, 8.00% 40.38%, 10.00% 40.59%, 12.00% 40.84%, 14.00% 41.14%, 16.00% 41.48%, 18.00% 41.87%, 20.00% 42.29%, 22.00% 42.75%, 24.00% 43.25%, 26.00% 43.79%, 28.00% 44.35%, 30.00% 44.95%, 32.00% 45.57%, 34.00% 46.22%, 36.00% 46.89%, 38.00% 47.58%, 40.00% 48.29%, 42.00% 49.02%, 44.00% 49.75%, 46.00% 50.50%, 48.00% 51.25%, 50.00% 52.00%, 52.00% 52.75%, 54.00% 53.50%, 56.00% 54.25%, 58.00% 54.98%, 60.00% 55.71%, 62.00% 56.42%, 64.00% 57.11%, 66.00% 57.78%, 68.00% 58.43%, 70.00% 59.05%, 72.00% 59.65%, 74.00% 60.21%, 76.00% 60.75%, 78.00% 61.25%, 80.00% 61.71%, 82.00% 62.13%, 84.00% 62.52%, 86.00% 62.86%, 88.00% 63.16%, 90.00% 63.41%, 92.00% 63.62%, 94.00% 63.79%, 96.00% 63.91%, 98.00% 63.98%, 100.00% 64.00%);
    }

    to {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 64.00%, 2.00% 63.98%, 4.00% 63.91%, 6.00% 63.79%, 8.00% 63.62%, 10.00% 63.41%, 12.00% 63.16%, 14.00% 62.86%, 16.00% 62.52%, 18.00% 62.13%, 20.00% 61.71%, 22.00% 61.25%, 24.00% 60.75%, 26.00% 60.21%, 28.00% 59.65%, 30.00% 59.05%, 32.00% 58.43%, 34.00% 57.78%, 36.00% 57.11%, 38.00% 56.42%, 40.00% 55.71%, 42.00% 54.98%, 44.00% 54.25%, 46.00% 53.50%, 48.00% 52.75%, 50.00% 52.00%, 52.00% 51.25%, 54.00% 50.50%, 56.00% 49.75%, 58.00% 49.02%, 60.00% 48.29%, 62.00% 47.58%, 64.00% 46.89%, 66.00% 46.22%, 68.00% 45.57%, 70.00% 44.95%, 72.00% 44.35%, 74.00% 43.79%, 76.00% 43.25%, 78.00% 42.75%, 80.00% 42.29%, 82.00% 41.87%, 84.00% 41.48%, 86.00% 41.14%, 88.00% 40.84%, 90.00% 40.59%, 92.00% 40.38%, 94.00% 40.21%, 96.00% 40.09%, 98.00% 40.02%, 100.00% 40.00%);
    }
`;

export const wave60 = () => keyframes`
    from {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 52.00%, 2.00% 51.94%, 4.00% 51.75%, 6.00% 51.44%, 8.00% 51.01%, 10.00% 50.47%, 12.00% 49.83%, 14.00% 49.10%, 16.00% 48.29%, 18.00% 47.41%, 20.00% 46.47%, 22.00% 45.50%, 24.00% 44.50%, 26.00% 43.50%, 28.00% 42.50%, 30.00% 41.53%, 32.00% 40.59%, 34.00% 39.71%, 36.00% 38.90%, 38.00% 38.17%, 40.00% 37.53%, 42.00% 36.99%, 44.00% 36.56%, 46.00% 36.25%, 48.00% 36.06%, 50.00% 36.00%, 52.00% 36.06%, 54.00% 36.25%, 56.00% 36.56%, 58.00% 36.99%, 60.00% 37.53%, 62.00% 38.17%, 64.00% 38.90%, 66.00% 39.71%, 68.00% 40.59%, 70.00% 41.53%, 72.00% 42.50%, 74.00% 43.50%, 76.00% 44.50%, 78.00% 45.50%, 80.00% 46.47%, 82.00% 47.41%, 84.00% 48.29%, 86.00% 49.10%, 88.00% 49.83%, 90.00% 50.47%, 92.00% 51.01%, 94.00% 51.44%, 96.00% 51.75%, 98.00% 51.94%, 100.00% 52.00%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 48.00%, 2.00% 47.10%, 4.00% 46.15%, 6.00% 45.17%, 8.00% 44.17%, 10.00% 43.16%, 12.00% 42.17%, 14.00% 41.21%, 16.00% 40.29%, 18.00% 39.43%, 20.00% 38.65%, 22.00% 37.94%, 24.00% 37.34%, 26.00% 36.83%, 28.00% 36.44%, 30.00% 36.17%, 32.00% 36.03%, 34.00% 36.01%, 36.00% 36.11%, 38.00% 36.34%, 40.00% 36.69%, 42.00% 37.16%, 44.00% 37.73%, 46.00% 38.40%, 48.00% 39.16%, 50.00% 40.00%, 52.00% 40.90%, 54.00% 41.85%, 56.00% 42.83%, 58.00% 43.83%, 60.00% 44.84%, 62.00% 45.83%, 64.00% 46.79%, 66.00% 47.71%, 68.00% 48.57%, 70.00% 49.35%, 72.00% 50.06%, 74.00% 50.66%, 76.00% 51.17%, 78.00% 51.56%, 80.00% 51.83%, 82.00% 51.97%, 84.00% 51.99%, 86.00% 51.89%, 88.00% 51.66%, 90.00% 51.31%, 92.00% 50.84%, 94.00% 50.27%, 96.00% 49.60%, 98.00% 48.84%, 100.00% 48.00%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 44.00%, 2.00% 45.00%, 4.00% 45.99%, 6.00% 46.94%, 8.00% 47.85%, 10.00% 48.70%, 12.00% 49.48%, 14.00% 50.16%, 16.00% 50.75%, 18.00% 51.24%, 20.00% 51.61%, 22.00% 51.86%, 24.00% 51.98%, 26.00% 51.98%, 28.00% 51.86%, 30.00% 51.61%, 32.00% 51.24%, 34.00% 50.75%, 36.00% 50.16%, 38.00% 49.48%, 40.00% 48.70%, 42.00% 47.85%, 44.00% 46.94%, 46.00% 45.99%, 48.00% 45.00%, 50.00% 44.00%, 52.00% 43.00%, 54.00% 42.01%, 56.00% 41.06%, 58.00% 40.15%, 60.00% 39.30%, 62.00% 38.52%, 64.00% 37.84%, 66.00% 37.25%, 68.00% 36.76%, 70.00% 36.39%, 72.00% 36.14%, 74.00% 36.02%, 76.00% 36.02%, 78.00% 36.14%, 80.00% 36.39%, 82.00% 36.76%, 84.00% 37.25%, 86.00% 37.84%, 88.00% 38.52%, 90.00% 39.30%, 92.00% 40.15%, 94.00% 41.06%, 96.00% 42.01%, 98.00% 43.00%, 100.00% 44.00%);
    }

    to {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 52.00%, 2.00% 51.94%, 4.00% 51.75%, 6.00% 51.44%, 8.00% 51.01%, 10.00% 50.47%, 12.00% 49.83%, 14.00% 49.10%, 16.00% 48.29%, 18.00% 47.41%, 20.00% 46.47%, 22.00% 45.50%, 24.00% 44.50%, 26.00% 43.50%, 28.00% 42.50%, 30.00% 41.53%, 32.00% 40.59%, 34.00% 39.71%, 36.00% 38.90%, 38.00% 38.17%, 40.00% 37.53%, 42.00% 36.99%, 44.00% 36.56%, 46.00% 36.25%, 48.00% 36.06%, 50.00% 36.00%, 52.00% 36.06%, 54.00% 36.25%, 56.00% 36.56%, 58.00% 36.99%, 60.00% 37.53%, 62.00% 38.17%, 64.00% 38.90%, 66.00% 39.71%, 68.00% 40.59%, 70.00% 41.53%, 72.00% 42.50%, 74.00% 43.50%, 76.00% 44.50%, 78.00% 45.50%, 80.00% 46.47%, 82.00% 47.41%, 84.00% 48.29%, 86.00% 49.10%, 88.00% 49.83%, 90.00% 50.47%, 92.00% 51.01%, 94.00% 51.44%, 96.00% 51.75%, 98.00% 51.94%, 100.00% 52.00%);
    }
`;

export const wave70 = () => keyframes`
    from {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 42.00%, 2.00% 41.95%, 4.00% 41.81%, 6.00% 41.58%, 8.00% 41.26%, 10.00% 40.85%, 12.00% 40.37%, 14.00% 39.82%, 16.00% 39.21%, 18.00% 38.55%, 20.00% 37.85%, 22.00% 37.12%, 24.00% 36.38%, 26.00% 35.62%, 28.00% 34.88%, 30.00% 34.15%, 32.00% 33.45%, 34.00% 32.79%, 36.00% 32.18%, 38.00% 31.63%, 40.00% 31.15%, 42.00% 30.74%, 44.00% 30.42%, 46.00% 30.19%, 48.00% 30.05%, 50.00% 30.00%, 52.00% 30.05%, 54.00% 30.19%, 56.00% 30.42%, 58.00% 30.74%, 60.00% 31.15%, 62.00% 31.63%, 64.00% 32.18%, 66.00% 32.79%, 68.00% 33.45%, 70.00% 34.15%, 72.00% 34.88%, 74.00% 35.62%, 76.00% 36.38%, 78.00% 37.12%, 80.00% 37.85%, 82.00% 38.55%, 84.00% 39.21%, 86.00% 39.82%, 88.00% 40.37%, 90.00% 40.85%, 92.00% 41.26%, 94.00% 41.58%, 96.00% 41.81%, 98.00% 41.95%, 100.00% 42.00%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 39.00%, 2.00% 38.33%, 4.00% 37.61%, 6.00% 36.88%, 8.00% 36.13%, 10.00% 35.37%, 12.00% 34.63%, 14.00% 33.91%, 16.00% 33.22%, 18.00% 32.58%, 20.00% 31.99%, 22.00% 31.46%, 24.00% 31.00%, 26.00% 30.63%, 28.00% 30.33%, 30.00% 30.13%, 32.00% 30.02%, 34.00% 30.01%, 36.00% 30.08%, 38.00% 30.26%, 40.00% 30.52%, 42.00% 30.87%, 44.00% 31.30%, 46.00% 31.80%, 48.00% 32.37%, 50.00% 33.00%, 52.00% 33.67%, 54.00% 34.39%, 56.00% 35.12%, 58.00% 35.87%, 60.00% 36.63%, 62.00% 37.37%, 64.00% 38.09%, 66.00% 38.78%, 68.00% 39.42%, 70.00% 40.01%, 72.00% 40.54%, 74.00% 41.00%, 76.00% 41.37%, 78.00% 41.67%, 80.00% 41.87%, 82.00% 41.98%, 84.00% 41.99%, 86.00% 41.92%, 88.00% 41.74%, 90.00% 41.48%, 92.00% 41.13%, 94.00% 40.70%, 96.00% 40.20%, 98.00% 39.63%, 100.00% 39.00%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 36.00%, 2.00% 36.75%, 4.00% 37.49%, 6.00% 38.21%, 8.00% 38.89%, 10.00% 39.53%, 12.00% 40.11%, 14.00% 40.62%, 16.00% 41.07%, 18.00% 41.43%, 20.00% 41.71%, 22.00% 41.89%, 24.00% 41.99%, 26.00% 41.99%, 28.00% 41.89%, 30.00% 41.71%, 32.00% 41.43%, 34.00% 41.07%, 36.00% 40.62%, 38.00% 40.11%, 40.00% 39.53%, 42.00% 38.89%, 44.00% 38.21%, 46.00% 37.49%, 48.00% 36.75%, 50.00% 36.00%, 52.00% 35.25%, 54.00% 34.51%, 56.00% 33.79%, 58.00% 33.11%, 60.00% 32.47%, 62.00% 31.89%, 64.00% 31.38%, 66.00% 30.93%, 68.00% 30.57%, 70.00% 30.29%, 72.00% 30.11%, 74.00% 30.01%, 76.00% 30.01%, 78.00% 30.11%, 80.00% 30.29%, 82.00% 30.57%, 84.00% 30.93%, 86.00% 31.38%, 88.00% 31.89%, 90.00% 32.47%, 92.00% 33.11%, 94.00% 33.79%, 96.00% 34.51%, 98.00% 35.25%, 100.00% 36.00%);
    }

    to {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 42.00%, 2.00% 41.95%, 4.00% 41.81%, 6.00% 41.58%, 8.00% 41.26%, 10.00% 40.85%, 12.00% 40.37%, 14.00% 39.82%, 16.00% 39.21%, 18.00% 38.55%, 20.00% 37.85%, 22.00% 37.12%, 24.00% 36.38%, 26.00% 35.62%, 28.00% 34.88%, 30.00% 34.15%, 32.00% 33.45%, 34.00% 32.79%, 36.00% 32.18%, 38.00% 31.63%, 40.00% 31.15%, 42.00% 30.74%, 44.00% 30.42%, 46.00% 30.19%, 48.00% 30.05%, 50.00% 30.00%, 52.00% 30.05%, 54.00% 30.19%, 56.00% 30.42%, 58.00% 30.74%, 60.00% 31.15%, 62.00% 31.63%, 64.00% 32.18%, 66.00% 32.79%, 68.00% 33.45%, 70.00% 34.15%, 72.00% 34.88%, 74.00% 35.62%, 76.00% 36.38%, 78.00% 37.12%, 80.00% 37.85%, 82.00% 38.55%, 84.00% 39.21%, 86.00% 39.82%, 88.00% 40.37%, 90.00% 40.85%, 92.00% 41.26%, 94.00% 41.58%, 96.00% 41.81%, 98.00% 41.95%, 100.00% 42.00%);
    }
`;

export const wave80 = () => keyframes`
    from {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 30.00%, 2.00% 29.99%, 4.00% 29.97%, 6.00% 29.93%, 8.00% 29.87%, 10.00% 29.80%, 12.00% 29.72%, 14.00% 29.62%, 16.00% 29.51%, 18.00% 29.38%, 20.00% 29.24%, 22.00% 29.08%, 24.00% 28.92%, 26.00% 28.74%, 28.00% 28.55%, 30.00% 28.35%, 32.00% 28.14%, 34.00% 27.93%, 36.00% 27.70%, 38.00% 27.47%, 40.00% 27.24%, 42.00% 26.99%, 44.00% 26.75%, 46.00% 26.50%, 48.00% 26.25%, 50.00% 26.00%, 52.00% 25.75%, 54.00% 25.50%, 56.00% 25.25%, 58.00% 25.01%, 60.00% 24.76%, 62.00% 24.53%, 64.00% 24.30%, 66.00% 24.07%, 68.00% 23.86%, 70.00% 23.65%, 72.00% 23.45%, 74.00% 23.26%, 76.00% 23.08%, 78.00% 22.92%, 80.00% 22.76%, 82.00% 22.62%, 84.00% 22.49%, 86.00% 22.38%, 88.00% 22.28%, 90.00% 22.20%, 92.00% 22.13%, 94.00% 22.07%, 96.00% 22.03%, 98.00% 22.01%, 100.00% 22.00%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 26.00%, 2.00% 25.75%, 4.00% 25.50%, 6.00% 25.25%, 8.00% 25.01%, 10.00% 24.76%, 12.00% 24.53%, 14.00% 24.30%, 16.00% 24.07%, 18.00% 23.86%, 20.00% 23.65%, 22.00% 23.45%, 24.00% 23.26%, 26.00% 23.08%, 28.00% 22.92%, 30.00% 22.76%, 32.00% 22.62%, 34.00% 22.49%, 36.00% 22.38%, 38.00% 22.28%, 40.00% 22.20%, 42.00% 22.13%, 44.00% 22.07%, 46.00% 22.03%, 48.00% 22.01%, 50.00% 22.00%, 52.00% 22.01%, 54.00% 22.03%, 56.00% 22.07%, 58.00% 22.13%, 60.00% 22.20%, 62.00% 22.28%, 64.00% 22.38%, 66.00% 22.49%, 68.00% 22.62%, 70.00% 22.76%, 72.00% 22.92%, 74.00% 23.08%, 76.00% 23.26%, 78.00% 23.45%, 80.00% 23.65%, 82.00% 23.86%, 84.00% 24.07%, 86.00% 24.30%, 88.00% 24.53%, 90.00% 24.76%, 92.00% 25.01%, 94.00% 25.25%, 96.00% 25.50%, 98.00% 25.75%, 100.00% 26.00%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 26.00%, 2.00% 26.25%, 4.00% 26.50%, 6.00% 26.75%, 8.00% 26.99%, 10.00% 27.24%, 12.00% 27.47%, 14.00% 27.70%, 16.00% 27.93%, 18.00% 28.14%, 20.00% 28.35%, 22.00% 28.55%, 24.00% 28.74%, 26.00% 28.92%, 28.00% 29.08%, 30.00% 29.24%, 32.00% 29.38%, 34.00% 29.51%, 36.00% 29.62%, 38.00% 29.72%, 40.00% 29.80%, 42.00% 29.87%, 44.00% 29.93%, 46.00% 29.97%, 48.00% 29.99%, 50.00% 30.00%, 52.00% 29.99%, 54.00% 29.97%, 56.00% 29.93%, 58.00% 29.87%, 60.00% 29.80%, 62.00% 29.72%, 64.00% 29.62%, 66.00% 29.51%, 68.00% 29.38%, 70.00% 29.24%, 72.00% 29.08%, 74.00% 28.92%, 76.00% 28.74%, 78.00% 28.55%, 80.00% 28.35%, 82.00% 28.14%, 84.00% 27.93%, 86.00% 27.70%, 88.00% 27.47%, 90.00% 27.24%, 92.00% 26.99%, 94.00% 26.75%, 96.00% 26.50%, 98.00% 26.25%, 100.00% 26.00%);
    }

    to {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 30.00%, 2.00% 29.99%, 4.00% 29.97%, 6.00% 29.93%, 8.00% 29.87%, 10.00% 29.80%, 12.00% 29.72%, 14.00% 29.62%, 16.00% 29.51%, 18.00% 29.38%, 20.00% 29.24%, 22.00% 29.08%, 24.00% 28.92%, 26.00% 28.74%, 28.00% 28.55%, 30.00% 28.35%, 32.00% 28.14%, 34.00% 27.93%, 36.00% 27.70%, 38.00% 27.47%, 40.00% 27.24%, 42.00% 26.99%, 44.00% 26.75%, 46.00% 26.50%, 48.00% 26.25%, 50.00% 26.00%, 52.00% 25.75%, 54.00% 25.50%, 56.00% 25.25%, 58.00% 25.01%, 60.00% 24.76%, 62.00% 24.53%, 64.00% 24.30%, 66.00% 24.07%, 68.00% 23.86%, 70.00% 23.65%, 72.00% 23.45%, 74.00% 23.26%, 76.00% 23.08%, 78.00% 22.92%, 80.00% 22.76%, 82.00% 22.62%, 84.00% 22.49%, 86.00% 22.38%, 88.00% 22.28%, 90.00% 22.20%, 92.00% 22.13%, 94.00% 22.07%, 96.00% 22.03%, 98.00% 22.01%, 100.00% 22.00%);
    }
`;

export const wave90 = () => keyframes`
    from {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 28.57%, 2.00% 28.52%, 4.00% 28.37%, 6.00% 28.13%, 8.00% 27.80%, 10.00% 27.39%, 12.00% 26.93%, 14.00% 26.42%, 16.00% 25.89%, 18.00% 25.36%, 20.00% 24.83%, 22.00% 24.34%, 24.00% 23.89%, 26.00% 23.51%, 28.00% 23.21%, 30.00% 23.00%, 32.00% 22.88%, 34.00% 22.86%, 36.00% 22.95%, 38.00% 23.13%, 40.00% 23.40%, 42.00% 23.76%, 44.00% 24.18%, 46.00% 24.66%, 48.00% 25.18%, 50.00% 25.71%, 52.00% 26.25%, 54.00% 26.77%, 56.00% 27.25%, 58.00% 27.67%, 60.00% 28.03%, 62.00% 28.30%, 64.00% 28.48%, 66.00% 28.57%, 68.00% 28.55%, 70.00% 28.43%, 72.00% 28.22%, 74.00% 27.92%, 76.00% 27.54%, 78.00% 27.09%, 80.00% 26.60%, 82.00% 26.07%, 84.00% 25.53%, 86.00% 25.00%, 88.00% 24.50%, 90.00% 24.03%, 92.00% 23.63%, 94.00% 23.30%, 96.00% 23.06%, 98.00% 22.91%, 100.00% 22.86%);
    }

    25% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 27.14%, 2.00% 26.65%, 4.00% 26.13%, 6.00% 25.59%, 8.00% 25.06%, 10.00% 24.55%, 12.00% 24.08%, 14.00% 23.67%, 16.00% 23.33%, 18.00% 23.08%, 20.00% 22.92%, 22.00% 22.86%, 24.00% 22.90%, 26.00% 23.04%, 28.00% 23.27%, 30.00% 23.59%, 32.00% 23.99%, 34.00% 24.44%, 36.00% 24.95%, 38.00% 25.48%, 40.00% 26.01%, 42.00% 26.54%, 44.00% 27.04%, 46.00% 27.49%, 48.00% 27.88%, 50.00% 28.19%, 52.00% 28.41%, 54.00% 28.54%, 56.00% 28.57%, 58.00% 28.50%, 60.00% 28.32%, 62.00% 28.06%, 64.00% 27.71%, 66.00% 27.30%, 68.00% 26.82%, 70.00% 26.31%, 72.00% 25.77%, 74.00% 25.24%, 76.00% 24.72%, 78.00% 24.23%, 80.00% 23.80%, 82.00% 23.44%, 84.00% 23.16%, 86.00% 22.96%, 88.00% 22.87%, 90.00% 22.87%, 92.00% 22.98%, 94.00% 23.18%, 96.00% 23.48%, 98.00% 23.85%, 100.00% 24.29%);
    }

    50% {
clip-path: polygon(100% 100%, 0% 100% , 0.00% 17.14%, 2.00% 17.68%, 4.00% 18.19%, 6.00% 18.67%, 8.00% 19.10%, 10.00% 19.45%, 12.00% 19.73%, 14.00% 19.91%, 16.00% 19.99%, 18.00% 19.98%, 20.00% 19.86%, 22.00% 19.65%, 24.00% 19.34%, 26.00% 18.96%, 28.00% 18.52%, 30.00% 18.03%, 32.00% 17.50%, 34.00% 16.96%, 36.00% 16.43%, 38.00% 15.93%, 40.00% 15.46%, 42.00% 15.06%, 44.00% 14.73%, 46.00% 14.49%, 48.00% 14.34%, 50.00% 14.29%, 52.00% 14.34%, 54.00% 14.49%, 56.00% 14.73%, 58.00% 15.06%, 60.00% 15.46%, 62.00% 15.93%, 64.00% 16.43%, 66.00% 16.96%, 68.00% 17.50%, 70.00% 18.03%, 72.00% 18.52%, 74.00% 18.96%, 76.00% 19.34%, 78.00% 19.65%, 80.00% 19.86%, 82.00% 19.98%, 84.00% 19.99%, 86.00% 19.91%, 88.00% 19.73%, 90.00% 19.45%, 92.00% 19.10%, 94.00% 18.67%, 96.00% 18.19%, 98.00% 17.68%, 100.00% 17.14%);
    }

    to {
      opacity: var(--o, 1);
clip-path: polygon(100% 100%, 0% 100% , 0.00% 28.57%, 2.00% 28.52%, 4.00% 28.37%, 6.00% 28.13%, 8.00% 27.80%, 10.00% 27.39%, 12.00% 26.93%, 14.00% 26.42%, 16.00% 25.89%, 18.00% 25.36%, 20.00% 24.83%, 22.00% 24.34%, 24.00% 23.89%, 26.00% 23.51%, 28.00% 23.21%, 30.00% 23.00%, 32.00% 22.88%, 34.00% 22.86%, 36.00% 22.95%, 38.00% 23.13%, 40.00% 23.40%, 42.00% 23.76%, 44.00% 24.18%, 46.00% 24.66%, 48.00% 25.18%, 50.00% 25.71%, 52.00% 26.25%, 54.00% 26.77%, 56.00% 27.25%, 58.00% 27.67%, 60.00% 28.03%, 62.00% 28.30%, 64.00% 28.48%, 66.00% 28.57%, 68.00% 28.55%, 70.00% 28.43%, 72.00% 28.22%, 74.00% 27.92%, 76.00% 27.54%, 78.00% 27.09%, 80.00% 26.60%, 82.00% 26.07%, 84.00% 25.53%, 86.00% 25.00%, 88.00% 24.50%, 90.00% 24.03%, 92.00% 23.63%, 94.00% 23.30%, 96.00% 23.06%, 98.00% 22.91%, 100.00% 22.86%);
    }
`;

export const wave100 = () => keyframes`
    from {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 14.00%, 2.00% 13.95%, 4.00% 13.81%, 6.00% 13.58%, 8.00% 13.26%, 10.00% 12.85%, 12.00% 12.37%, 14.00% 11.82%, 16.00% 11.21%, 18.00% 10.55%, 20.00% 9.85%, 22.00% 9.12%, 24.00% 8.38%, 26.00% 7.62%, 28.00% 6.88%, 30.00% 6.15%, 32.00% 5.45%, 34.00% 4.79%, 36.00% 4.18%, 38.00% 3.63%, 40.00% 3.15%, 42.00% 2.74%, 44.00% 2.42%, 46.00% 2.19%, 48.00% 2.05%, 50.00% 2.00%, 52.00% 2.05%, 54.00% 2.19%, 56.00% 2.42%, 58.00% 2.74%, 60.00% 3.15%, 62.00% 3.63%, 64.00% 4.18%, 66.00% 4.79%, 68.00% 5.45%, 70.00% 6.15%, 72.00% 6.88%, 74.00% 7.62%, 76.00% 8.38%, 78.00% 9.12%, 80.00% 9.85%, 82.00% 10.55%, 84.00% 11.21%, 86.00% 11.82%, 88.00% 12.37%, 90.00% 12.85%, 92.00% 13.26%, 94.00% 13.58%, 96.00% 13.81%, 98.00% 13.95%, 100.00% 14.00%);
    }

    25% {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 11.00%, 2.00% 10.33%, 4.00% 9.61%, 6.00% 8.88%, 8.00% 8.13%, 10.00% 7.37%, 12.00% 6.63%, 14.00% 5.91%, 16.00% 5.22%, 18.00% 4.58%, 20.00% 3.99%, 22.00% 3.46%, 24.00% 3.00%, 26.00% 2.63%, 28.00% 2.33%, 30.00% 2.13%, 32.00% 2.02%, 34.00% 2.01%, 36.00% 2.08%, 38.00% 2.26%, 40.00% 2.52%, 42.00% 2.87%, 44.00% 3.30%, 46.00% 3.80%, 48.00% 4.37%, 50.00% 5.00%, 52.00% 5.67%, 54.00% 6.39%, 56.00% 7.12%, 58.00% 7.87%, 60.00% 8.63%, 62.00% 9.37%, 64.00% 10.09%, 66.00% 10.78%, 68.00% 11.42%, 70.00% 12.01%, 72.00% 12.54%, 74.00% 13.00%, 76.00% 13.37%, 78.00% 13.67%, 80.00% 13.87%, 82.00% 13.98%, 84.00% 13.99%, 86.00% 13.92%, 88.00% 13.74%, 90.00% 13.48%, 92.00% 13.13%, 94.00% 12.70%, 96.00% 12.20%, 98.00% 11.63%, 100.00% 11.00%);
    }

    50% {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 2.00%, 2.00% 2.05%, 4.00% 2.19%, 6.00% 2.42%, 8.00% 2.74%, 10.00% 3.15%, 12.00% 3.63%, 14.00% 4.18%, 16.00% 4.79%, 18.00% 5.45%, 20.00% 6.15%, 22.00% 6.88%, 24.00% 7.62%, 26.00% 8.38%, 28.00% 9.12%, 30.00% 9.85%, 32.00% 10.55%, 34.00% 11.21%, 36.00% 11.82%, 38.00% 12.37%, 40.00% 12.85%, 42.00% 13.26%, 44.00% 13.58%, 46.00% 13.81%, 48.00% 13.95%, 50.00% 14.00%, 52.00% 13.95%, 54.00% 13.81%, 56.00% 13.58%, 58.00% 13.26%, 60.00% 12.85%, 62.00% 12.37%, 64.00% 11.82%, 66.00% 11.21%, 68.00% 10.55%, 70.00% 9.85%, 72.00% 9.12%, 74.00% 8.38%, 76.00% 7.62%, 78.00% 6.88%, 80.00% 6.15%, 82.00% 5.45%, 84.00% 4.79%, 86.00% 4.18%, 88.00% 3.63%, 90.00% 3.15%, 92.00% 2.74%, 94.00% 2.42%, 96.00% 2.19%, 98.00% 2.05%, 100.00% 2.00%);
    }

    to {
      opacity: var(--o, 1);
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 14.00%, 2.00% 13.95%, 4.00% 13.81%, 6.00% 13.58%, 8.00% 13.26%, 10.00% 12.85%, 12.00% 12.37%, 14.00% 11.82%, 16.00% 11.21%, 18.00% 10.55%, 20.00% 9.85%, 22.00% 9.12%, 24.00% 8.38%, 26.00% 7.62%, 28.00% 6.88%, 30.00% 6.15%, 32.00% 5.45%, 34.00% 4.79%, 36.00% 4.18%, 38.00% 3.63%, 40.00% 3.15%, 42.00% 2.74%, 44.00% 2.42%, 46.00% 2.19%, 48.00% 2.05%, 50.00% 2.00%, 52.00% 2.05%, 54.00% 2.19%, 56.00% 2.42%, 58.00% 2.74%, 60.00% 3.15%, 62.00% 3.63%, 64.00% 4.18%, 66.00% 4.79%, 68.00% 5.45%, 70.00% 6.15%, 72.00% 6.88%, 74.00% 7.62%, 76.00% 8.38%, 78.00% 9.12%, 80.00% 9.85%, 82.00% 10.55%, 84.00% 11.21%, 86.00% 11.82%, 88.00% 12.37%, 90.00% 12.85%, 92.00% 13.26%, 94.00% 13.58%, 96.00% 13.81%, 98.00% 13.95%, 100.00% 14.00%);
    }
`;

export const waveAnims = [
  wave10,
  wave20,
  wave30,
  wave40,
  wave50,
  wave60,
  wave70,
  wave80,
  wave90,
  wave100,
];
