import React from 'react';

import Button from 'components/Button/Button';
import TermsAndConditions from 'components/TermsAndConditions/TermsAndConditions';
import { useCopyStore } from 'store';
import useThemeStore from 'store/visuals';
import { fadeMotionProps } from 'utils/styles/animations';

import * as Styled from './TermsModal.styles';

export interface TermsModalProps {
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ onClose }) => {
  const { userLang } = useCopyStore();
  const copy = useCopyStore(({ copy }) => copy.global.landing);
  const { theme } = useThemeStore();

  return (
    <Styled.Wrapper {...fadeMotionProps}>
      <div className="dimBg" />
      <div className="wrapper">
        <TermsAndConditions locale={userLang} />
        <Button
          label={copy.closeCta}
          imgUrl={theme.ctas?.introClose}
          onClick={onClose}
        />
      </div>
    </Styled.Wrapper>
  );
};

export default TermsModal;
