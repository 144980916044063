import styled from 'styled-components';

import { Wrapper } from 'containers/styles/index.styles';
import { setTypography, setVh } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const WrapperSourceSelection = styled(Wrapper)`
  height: ${setVh(100)};
  width: 100vw;
  user-select: none;

  > .topWrapper {
    position: absolute;
    top: 35rem;

    > img {
      display: block;
      margin: 0 auto;
      width: auto;
    }

    > img:first-of-type {
      height: 200rem;
    }

    > img:last-of-type,
    > .heading {
      font-size: 70rem;
      height: 400rem;
      margin: 100rem auto 0;
    }
  }

  > .bottomWrapper {
    background-color: ${colors.white};
    position: absolute;
    bottom: 0;
    border-radius: 5px 5px 0 0;
    height: 800rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    > p {
      text-align: center;
      padding: 70rem 60rem 0;
      color: ${colors.alto};
      font-size: 60rem;
      ${({ theme: { locale } }) => setTypography('heading2', locale)}
      font-size: ${({ theme: { locale } }) => locale === 'vi' && '41rem'};
    }

    button {
      font-size: 60rem;
    }

    > button:last-of-type {
      width: 90%;
      padding: 0 10rem;
      ${({ theme: { locale } }) => setTypography('button', locale)}
      font-size: ${({ theme: { locale } }) => locale === 'vi' && '48rem'};

      > img {
        height: ${({ theme: { locale } }) => locale === 'es' && '68rem'};
      }
    }

    > div {
      display: flex;
      padding: 0 60rem;

      > button {
        color: ${colors.alto};
        margin-bottom: 60rem;
        text-align: left;
        font-size: 45rem;
        ${({ theme: { locale } }) => setTypography('terms', locale)}
        font-size: ${({ theme: { locale } }) => locale === 'vi' && '30rem'};

        > a {
          font-family: 'Roboto Bold';
          ${({ theme }) => `color: ${theme.global.colors.font};`}
          text-decoration: underline;
          text-underline-offset: 3px;
        }
      }
    }
  }
`;

export const FakedCheckbox = styled.span`
  height: 45rem;
  width: 45rem;
  margin-right auto;
  border-radius: 2px;
  position: relative;
  margin: 10rem 50rem 0 10rem;

  > svg {
    transform: scale(1.6);
    ${({ theme }) => `color: ${theme.global.colors.font};`}
  }
`;

export const HiddenInput = styled.section`
  position: relative;

  > input {
    opacity: 0;
    visibility: none;
    position: absolute;
    left: 0;
    z-index: 2;
    height: 100%;

    &.disabled {
      pointer-events: none;
    }
  }

  > button {
    ${({ theme: { locale } }) => setTypography('heading2', locale)}
    ${({ theme }) => `color: ${theme.global.colors.font} !important;`}
    font-size: 55rem !important;
    padding-left: 80rem !important;
    padding-right: 80rem !important;
    width: 100% !important;

    position: relative;
    margin-bottom: ${({ theme: { locale } }) => locale === 'vi' && '40rem'};

    > span {
      display: inline-block;
      ${({ theme }) => `background-color: ${theme.global.colors.font};`}
      color: ${colors.white};
      padding: 10rem 17rem 5rem 17rem;
      padding: ${({ theme: { locale } }) =>
        locale === 'vi' && '6rem 17rem 6rem 17rem'};
      border-radius: 3px;
      position: relative;
      top: 5rem;
      vertical-align: text-bottom;
    }
  }
`;

export const DotsLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  > span {
    display: inline-block;
    width: 42rem;
    height: 42rem;
    background-color: ${colors.crusta};
    border-radius: 50%;
    margin: 0 21rem;
    animation: dotAnimation 2s 0.2s infinite;

    &:first-child {
      animation: dotAnimation 2s infinite;
    }
    &:last-child {
      animation: dotAnimation 2s 0.3s infinite;
    }
  }

  @keyframes dotAnimation {
    0% {
      background-color: ${colors.crusta};
    }
    50% {
      background-color: ${colors.white};
    }
    100% {
      background-color: ${colors.crusta};
    }
  }
`;

export const TermsButton = styled.button`
  font-family: 'Roboto Bold';
  font-size: 35rem !important;
  font-size: ${({ theme: { locale } }) =>
    locale === 'vi' && '30rem !important'};
  ${({ theme }) => `color: ${theme.global.colors.font};`}
  text-decoration: underline;
  text-underline-offset: 3px;
`;
