import styled, { css } from 'styled-components';

import { waveAnims } from './wave.anims';

interface Props {
  $waveAnimNo: number;
}

export const Wrapper = styled.div<Props>`
  .outer {
    aspect-ratio: 3 / 5.5;
    transform: scale(1.4);
  }

  .can {
    height: 30vmin;
    width: 15vmin;
  }

  > div p,
  .can {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > span:first-of-type {
      font-size: 60rem;
      margin-inline-start: 15rem;
    }

    > span:last-of-type {
      position: relative;
      font-size: 35rem;
      margin-inline-start: 5rem;
      bottom: 10rem;
    }
  }

  .liquid {
    inset: 0;

    .bg {
      position: absolute;
      inset: 0;
      ${({ theme }) => `background-image: url(${theme.processing.emptyCan});`}
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 110%;

      &:before,
      &:after {
        content: '';
        position: absolute;
        inset: 0;
        ${({ theme }) => `background-image: url(${theme.processing.fullCan});`}
        background-repeat: inherit;
        background-position: inherit;
        background-size: inherit;
        animation: ${waveAnims[0]} 1s linear infinite;

        ${({ $waveAnimNo }) =>
          $waveAnimNo &&
          css`
            animation-name: ${waveAnims[$waveAnimNo]};
          `};
      }

      &:after {
        transform: scaleX(-1) translateX(-7rem);
        opacity: 0; // initial state before animation with delay
        --o: 0.6; // opacity during animation
        animation-delay: 0.55s;
      }
    }
  }
`;
